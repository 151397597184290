import React, { Suspense } from "react";
import LiveMatches from "./Components/Cricket/LiveMatch/LiveMatches";
import News from "./Components/Cricket/News/cricket_news";
import RecentMatches from "./Components/Cricket/RecentMatches/RecentMatches";
import UpcomingMatches from "./Components/Cricket/UpcommingMatches/UpcomingMatches";
import upcomingIcon from "../src/upcoming.png";
import recentIcon from "../src/recent.png";
import newsIcon from "../src/newspaper.png";
// import { ChatBubbleBottomCenterIcon } from "@heroicons/react/24/solid";
import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
} from "@material-tailwind/react";

// import {
//   TrophyIcon,
//   PaperAirplaneIcon,
//   ChatBubbleBottomCenterTextIcon,
// } from "@heroicons/react/24/solid";

import UpcomingList from "./Components/Cricket/UpcommingMatches/upcomingListStyle";
// import { ClassNames } from "@emotion/react";
const NewsMobile = React.lazy(() =>
  import("./Components/Cricket/News/mobile_news")
);
const RecentMatchesMobile = React.lazy(() =>
  import("./Components/Cricket/RecentMatches/recentMatchesMobile")
);

function App2() {
  // const [tab, setTab] = React.useState(1);

  const data = [
    {
      label: "Upcoming",
      value: "upcoming",
      icon: upcomingIcon,
      desc: <UpcomingList />,
    },
    {
      label: "Finished",
      value: "finished",
      icon: recentIcon,
      desc: <RecentMatchesMobile />,
    },
    {
      label: "News",
      value: "news",
      icon: newsIcon,
      desc: <NewsMobile />,
    },
  ];

  return (
    <div className="App">
      <Suspense
        fallback={<h1 className="text-white">Loading Live Matches...</h1>}
      >
        <LiveMatches page="homepage" />
      </Suspense>
      <Suspense fallback={<h1 className="text-white">Loading Data...</h1>}>
        <div className="lg:block hidden">
          <UpcomingMatches page="homepage" key="homepage" />
        </div>
        <div className="hidden lg:flex flex-row w-10/12 mx-auto">
          <News className="" page="homepage" key="homepage" />
          <RecentMatches className="" page="homepage" key="homepage" />
        </div>
      </Suspense>
      <div className="flex flex-col lg:hidden">
        {/* <div class="mb-4 border-b border-gray-200 dark:border-gray-700 w-full">
          <ul
            class="flex -mb-px text-sm font-medium text-center"
            role="tablist"
          >
            <li class="mr-2 w-1/3" role="presentation">
              <button
                class={`w-full inline-block p-2 border-b-2 border-transparent rounded-t-lg text-sm ${
                  tab === 1
                    ? "text-sky-600 border-y-sky-600 font-bold"
                    : " text-slate-300 hover:text-opacity-50"
                }`}
                type="button"
                role="tab"
                onClick={() => {
                  setTab(1);
                }}
              >
                Upcoming
              </button>
            </li>
            <li class="mr-2 w-1/3" role="presentation">
              <button
                class={`w-full inline-block p-2 border-b-2 border-transparent rounded-t-lg text-sm ${
                  tab === 2
                    ? "text-sky-600 border-y-sky-600 font-bold"
                    : " text-slate-300 hover:text-opacity-50"
                }`}
                type="button"
                role="tab"
                onClick={() => {
                  setTab(2);
                }}
              >
                Recent
              </button>
            </li>
            <li class="mr-2 w-1/3" role="presentation">
              <button
                class={`w-full inline-block p-2 border-b-2 border-transparent rounded-t-lg text-sm ${
                  tab === 3
                    ? "text-sky-600 border-y-sky-600 font-bold"
                    : " text-slate-300 hover:text-opacity-50"
                }`}
                type="button"
                role="tab"
                onClick={() => {
                  setTab(3);
                }}
              >
                News
              </button>
            </li>
          </ul>
        </div>
        <div id="myTabContent">
          <div class={`${tab !== 1 && "hidden"} rounded-lg`} role="tabpanel">
            <UpcomingList
              className="grow w-1/4"
              page="homepage"
              key="mobileHomepage"
            />
          </div>
          <div class={`${tab !== 2 && "hidden"} rounded-lg`} role="tabpanel">
            <RecentMatchesMobile
              className="grow w-1/4"
              page="homepage"
              key="mobileHomepage"
            />
          </div>
          <div
            class={`${tab !== 3 && "hidden"} p-2 rounded-lg`}
            role="tabpanel"
          >
            <NewsMobile
              className="grow w-2/4"
              page="homepage"
              key="mobileHomepage"
            />
          </div>
        </div> */}

        <Tabs value="upcoming">
          <TabsHeader className="bg-gray-800 rounded-none">
            {data.map(({ label, value, icon }) => (
              <Tab key={value} value={value} activeClassName="z-20">
                <div className="flex items-center gap-2 text-white ">
                  {/* {React.createElement(icon, { className: "w-5 h-5" })} */}
                  <img className="w-5 h-5" src={icon} alt="value" />
                  {label}
                </div>
              </Tab>
            ))}
          </TabsHeader>
          <TabsBody>
            {data.map(({ value, desc }) => (
              <TabPanel key={value} value={value} className="p-0">
                {desc}
              </TabPanel>
            ))}
          </TabsBody>
        </Tabs>
      </div>
    </div>
  );
}

export default App2;
