import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import wsContext from "../../Context/wsContext";
import MasterCreate from "./userCreatingForm";
import Masters from "./masters";
import { Formik, Field, Form } from "formik";

const Gridcard = () => {
  const ws = useContext(wsContext);
  const userData = useSelector((state) => state.user);
  const [users, setUsers] = useState(undefined);
  const [masters, setMasters] = useState(undefined);
  const [subscribers, setSubscribers] = useState(undefined);
  const [error, setError] = useState(undefined);
  const wsAction1 = "get_users_database";
  const wsAction2 = "get_subscription_database";
  const wsAction3 = "get_edit_response";
  const wsKey = "header";

  useEffect(() => {
    if (userData) {
      if (userData.user.type == "Owner") {
        ws.register(wsAction1, wsKey, async function (data) {
          setUsers(data);
          const masters = await data.filter((usr) => {
            return usr.type == "Master";
          });
          setMasters(masters);
          const subscribers = await data.filter((usr) => {
            return usr.subscription !== null;
          });
          setSubscribers(subscribers);
        });
        ws.get(wsAction1, undefined, wsKey, undefined, undefined);
      } else if (userData.user.type == "Master") {
        ws.register(wsAction1, wsKey, async function (data) {
          // console.log(15, data);
          setUsers(data);
          const subscribers = await data.filter((usr) => {
            return usr.subscription !== null;
          });
          setSubscribers(subscribers);
        });
        ws.get(wsAction1, userData.user.id, wsKey, undefined, undefined);
      } else {
        setUsers([]);
      }
    }
    return () => {
      setUsers(null);
      setMasters(null);
      ws.unregister(wsAction1, wsKey);
      ws.unregister(wsAction2, wsKey);
    };
  }, [userData, ws]);

  return userData && users ? (
    <div className="overflow-hidden  shadow sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-base font-semibold leading-6 text-slate-300">
          Account Information
        </h3>
      </div>
      <div className="border-t border-gray-200">
        <dl>
          <div className=" px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Full name</dt>
            <dd className="mt-1 text-sm text-slate-300 sm:col-span-2 sm:mt-0">
              {`${userData.user.firstName} ${userData.user.lastName} `}
            </dd>
          </div>
          <div className=" px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Email address</dt>
            <dd className="mt-1 text-sm text-slate-300 sm:col-span-2 sm:mt-0">
              {userData.user.email}
            </dd>
          </div>
          <div className=" px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Role</dt>
            <dd className="mt-1 text-sm text-slate-300 sm:col-span-2 sm:mt-0">
              {userData.user.type}
            </dd>
          </div>
          {userData.user.type === "Subscriber" && (
            <>
              <div className=" px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  Subscription
                </dt>
                <dd className="mt-1 text-sm text-slate-300 sm:col-span-2 sm:mt-0">
                  {`${userData.user.subscription} Days`}
                </dd>
              </div>
              <div className=" px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  Start Date
                </dt>
                <dd className="mt-1 text-sm text-slate-300 sm:col-span-2 sm:mt-0">
                  {`${userData.user.startDate}`}
                </dd>
              </div>
              <div className=" px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">End Date</dt>
                <dd className="mt-1 text-sm text-slate-300 sm:col-span-2 sm:mt-0">
                  {`${userData.user.endDate}`}
                </dd>
              </div>
            </>
          )}
          {userData.user.type === "Master" && (
            <>
              <div className=" px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  Max Points
                </dt>
                <dd className="mt-1 text-sm text-slate-300 sm:col-span-2 sm:mt-0">
                  {userData.user.points}
                </dd>
              </div>
              <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  Used Points
                </dt>
                <dd className="mt-1 text-sm text-slate-300 sm:col-span-2 sm:mt-0">
                  {userData.user.usedPoints}
                </dd>
              </div>

              <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  Remaining Points
                </dt>
                <dd className="mt-1 text-sm text-slate-300 sm:col-span-2 sm:mt-0">
                  {userData.user.points - userData.user.usedPoints}
                </dd>
              </div>
            </>
          )}
          {(userData.user.type === "Master" ||
            userData.user.type === "Owner") && (
            <>
              <div className=" px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  Total Clients
                </dt>
                <dd className="mt-1 text-sm text-slate-300 sm:col-span-2 sm:mt-0">
                  {users.length}
                </dd>
              </div>
              {userData.user.type == "Owner" && (
                <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">
                    Total Masters
                  </dt>
                  <dd className="mt-1 text-sm text-slate-300 sm:col-span-2 sm:mt-0">
                    {masters.length}
                  </dd>
                </div>
              )}
              <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  Subscribed Clients
                </dt>
                <dd className="mt-1 text-sm text-slate-300 sm:col-span-2 sm:mt-0">
                  {subscribers.length}
                </dd>
              </div>
            </>
          )}
        </dl>
      </div>
      {userData.user.type === "Master" && (
        <>
          <Formik
            initialValues={{
              id: userData.user.id,
              banner: userData.user.banner,
              instagram: userData.user.instagram,
              whatsapp: userData.user.whatsapp,
              facebook: userData.user.facebook,
            }}
            onSubmit={async (values) => {
              ws.register(wsAction3, wsKey, async function (data) {
                data.id
                  ? setError("Success")
                  : setError("Error in banner updation !!");
              });
              ws.get(
                wsAction3,
                undefined,
                "header",
                undefined,
                undefined,
                values
              );
              return () => {
                ws.unregister(wsAction3, wsKey);
              };
            }}
          >
            <Form className="flex flex-col w-full justify-center items-center">
              {error && (
                <h1
                  className={` p-2 rounded-lg text-slate-900 ${
                    error !== "Success" ? "bg-red-500" : "bg-green-500"
                  }`}
                >
                  {error !== "Success"
                    ? error
                    : error === "Success"
                    ? "Action Successfull"
                    : ""}
                </h1>
              )}

              <div className="py-5 flex flex-col px-6 lg:w-2/3 w-full justify-between self-center">
                <div className="flex flex-col justify-between w-full">
                  <label
                    className="lg:text-base text-sm font-medium text-gray-500 self-center"
                    htmlFor="banner"
                  >
                    Banner
                  </label>
                  <Field
                    component="textarea"
                    rows="4"
                    className="rounded-lg"
                    id="banner"
                    name="banner"
                    placeholder={userData.user.banner}
                    type="text"
                  />
                  <label
                    className="lg:text-base text-sm font-medium text-gray-500 self-center"
                    htmlFor="instagram"
                  >
                    Instagram ID
                  </label>
                  <Field
                    className="rounded-lg"
                    id="instagram"
                    name="instagram"
                    placeholder={userData.user.instagram}
                    type="text"
                  />
                  <label
                    className="lg:text-base text-sm font-medium text-gray-500 self-center"
                    htmlFor="whatsapp"
                  >
                    Whatsapp Number
                  </label>
                  <Field
                    className="rounded-lg"
                    id="whatsapp"
                    name="whatsapp"
                    placeholder={userData.user.whatsapp}
                    type="number"
                  />
                  <label
                    className="lg:text-base text-sm font-medium text-gray-500 self-center"
                    htmlFor="facebook"
                  >
                    Facebook ID
                  </label>
                  <Field
                    className="rounded-lg"
                    id="facebook"
                    name="facebook"
                    placeholder={userData.user.facebook}
                    type="text"
                  />
                </div>
                <div className=" flex justify-center items-center my-2">
                  <button
                    type="submit"
                    class=" inline-flex w-full items-center text-white justify-center bg-green-600 hover:bg-green-700 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-green-500 dark:hover:bg-green-600 dark:focus:ring-green-900"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </Form>
          </Formik>
        </>
      )}
      {(userData.user.type === "Owner" || userData.user.type === "Master") && (
        <>
          <MasterCreate
            master={userData.user.type == "Owner" ? masters : undefined}
          />
          <Masters
            master={userData.user.type == "Owner" ? masters : undefined}
            data={users}
          />
        </>
      )}
    </div>
  ) : (
    <h1 className="text-slate-300">Loading Info.. </h1>
  );
};

export default Gridcard;
