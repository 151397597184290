import * as React from "react";
import wsContext from "../../Context/wsContext";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Cookies from "universal-cookie";
import Logo from "../../Red Modern Label Logo.png";

export default function SignIn(props) {
  var ws = React.useContext(wsContext);
  const cookies = new Cookies();
  const [error, setError] = React.useState();
  const wsAction1 = "get_login_response";
  const wsKey = "login_page";
  const dispatch = useDispatch();
  const navigate = useNavigate();

  React.useEffect(() => {
    ws.get(undefined, undefined, "onloginpage");
  }, []);

  const emailRef = React.useRef();
  const passRef = React.useRef();

  const updateProfile = (data) => {
    dispatch({ type: "updateProfile", payload: data });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const values = {
      identifier: emailRef.current.value,
      password: passRef.current.value,
    };
    ws.register(wsAction1, wsKey, async function (data) {
      if (!data.message) {
        ws.isAuthenticated = true;
        updateProfile(data);
        cookies.set("authtoken", data.jwt, {
          path: "/",
          maxAge: 60 * 60 * 24 * 365,
          expires: new Date(
            new Date().setFullYear(new Date().getFullYear() + 1)
          ),
          sameSite: true,
        });
        navigate("/");
      } else {
        setError(data.message);
      }
    });
    ws.get(wsAction1, undefined, props.page, undefined, undefined, values);
    return () => {
      ws.unregister(wsAction1, wsKey);
    };
  };

  return (
    <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="w-full max-w-md space-y-8">
        <div>
          <img className="mx-auto h-12 w-auto" src={Logo} alt="Your Company" />
          <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-blue-500">
            Sign in to your account
          </h2>
        </div>
        {error && (
          <h1 className="text-slate-900 bg-red-500 p-2 text-center rounded-lg">
            {error}
          </h1>
        )}
        <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
          <input type="hidden" name="remember" defaultValue="true" />
          <div className="-space-y-px rounded-md shadow-sm">
            <div>
              <label htmlFor="username" className="sr-only">
                Email/Username
              </label>
              <input
                ref={emailRef}
                id="username"
                name="username"
                type="text"
                autoComplete="username"
                required
                className="relative block w-full rounded-t-md border-0 py-1.5 text-slate-300 bg-slate-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-slate-800 sm:text-sm sm:leading-6"
                placeholder="Email/Username "
              />
            </div>
            <div>
              <label htmlFor="password" className="sr-only">
                Password
              </label>
              <input
                ref={passRef}
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                required
                className="relative block w-full rounded-b-md border-0 py-1.5 text-slate-300 bg-slate-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-slate-800 sm:text-sm sm:leading-6"
                placeholder="Password"
              />
            </div>
          </div>

          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <input
                id="remember-me"
                name="remember-me"
                type="checkbox"
                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
              <label
                htmlFor="remember-me"
                className="ml-2 block text-sm text-gray-400"
              >
                Remember me
              </label>
            </div>

            <div className="text-sm">
              <a
                href="/"
                className="font-medium text-indigo-600 hover:text-indigo-500"
              >
                Forgot your password?
              </a>
            </div>
          </div>

          <div>
            <button
              type="submit"
              className="group relative flex w-full justify-center rounded-md bg-indigo-600 py-2 px-3 text-sm font-semibold text-white hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                {/* <LockClosedIcon className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400" aria-hidden="true" /> */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z"
                  />
                </svg>
              </span>
              Sign in
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
