import React from "react";
import { useAsyncDebounce } from "react-table";

function useGlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 300);
  return (
    <div className="flex justify-center items-center my-2">
      <div class="relative w-1/2">
        <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
          <svg
            aria-hidden="true"
            class="w-5 h-5 text-gray-500 dark:text-gray-400"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </div>
        <input
          type="text"
          onChange={(e) => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-slate-700 focus:border-slate-700 block w-full pl-10 p-1.5  dark:bg-gray-700 dark:border-gray-500 dark:placeholder-gray-500 dark:text-white dark:focus:ring-slate-500 dark:focus:border-slate-500"
          placeholder={`${count} records...`}
          required
        ></input>
      </div>
    </div>
  );
}
export default useGlobalFilter;
