import wsContext from "../../../Context/wsContext";
import React, { useState, useEffect, useContext, lazy } from "react";
import { useParams } from "react-router-dom";
import LiveMatchCard from "./LiveSingleMatch";
const PointsTable = lazy(() => import("./PointsTable"));
const Commentary = lazy(() => import("./Commentary"));
const ScoreCard = lazy(() => import("./ScoreCard"));
const OddsHistory = lazy(() => import("./OddsHistory"));
const Info = lazy(() => import("./InfoTab"));

const DetailedMatch = (props) => {
  const params = useParams();
  const matchId = params.matchId;
  const isLive = params.isLive;
  const [value, setValue] = useState(1);

  return (
    <React.Fragment>
      <div className="bg-website-background-color overflow-hidden">
        <LiveMatchCard />
        <div className="flex flex-col ">
          <div class="lg:w-4/5 lg:mx-auto border-b border-gray-200 dark:border-gray-700">
            <ul
              class="flex lg:w-6/12 -mb-px text-sm font-medium text-center [&::-webkit-scrollbar]:hidden overflow-x-auto whitespace-nowrap overflow-hidden"
              role="tablist"
            >
              <li class="mr-2 w-2/6">
                <button
                  class={`w-full inline-block p-2 border-b-2 border-transparent rounded-t-lg  text-base font-normal	antialiased  ${
                    value === 1
                      ? "text-sky-500 border-y-sky-500  font-bold"
                      : "text-slate-300 hover:text-opacity-50"
                  }`}
                  type="button"
                  role="tab"
                  onClick={() => {
                    setValue(1);
                  }}
                >
                  Commentary
                </button>
              </li>
              <li class="mr-2 w-2/6">
                <button
                  class={`w-full inline-block p-2 border-b-2 border-transparent rounded-t-lg text-base font-normal antialiased ${
                    value === 2
                      ? "text-sky-500 border-y-sky-500  font-bold"
                      : "text-slate-300 hover:text-opacity-50"
                  }`}
                  type="button"
                  role="tab"
                  onClick={() => {
                    setValue(2);
                  }}
                >
                  Score Card
                </button>
              </li>
              <li class="mr-2 w-2/6">
                <button
                  class={`w-full inline-block p-2 border-b-2 border-transparent rounded-t-lg text-base font-normal antialiased ${
                    value === 3
                      ? "text-sky-500 border-y-sky-500  font-bold"
                      : "text-slate-300 hover:text-opacity-50"
                  }`}
                  type="button"
                  role="tab"
                  onClick={() => {
                    setValue(3);
                  }}
                >
                  Odds
                </button>
              </li>
              <li class="mr-2 w-2/6">
                <button
                  class={`w-full inline-block p-2 border-b-2 border-transparent rounded-t-lg text-base font-normal antialiased ${
                    value === 4
                      ? "text-sky-500 border-y-sky-500  font-bold"
                      : "text-slate-300 hover:text-opacity-50"
                  }`}
                  type="button"
                  role="tab"
                  onClick={() => {
                    setValue(4);
                  }}
                >
                  Point Table
                </button>
              </li>
              <li class="mr-2 w-2/6">
                <button
                  class={`w-full inline-block p-2 border-b-2 border-transparent rounded-t-lg text-base font-normal antialiased ${
                    value === 5
                      ? "text-sky-500 border-y-sky-500 font-bold"
                      : "text-slate-300 hover:text-opacity-50"
                  }`}
                  type="button"
                  role="tab"
                  onClick={() => {
                    setValue(5);
                  }}
                >
                  Info
                </button>
              </li>
            </ul>
          </div>
          <div>
            <div
              class={`${value !== 1 && "hidden"} rounded-lg`}
              role="tabpanel"
            >
              <Commentary
                page="singlelivepage"
                subpage="commentary-tab"
                isLive={isLive}
              />
            </div>
            <div
              class={`${value !== 2 && "hidden"}  rounded-lg`}
              role="tabpanel"
            >
              <ScoreCard
                page="singlelivepage"
                subpage="scorecard-tab"
                isLive={isLive}
              />
            </div>
            <div
              class={`${value !== 3 && "hidden"}  rounded-lg`}
              role="tabpanel"
            >
              <OddsHistory
                page="singlelivepage"
                subpage="oddshistory-tab"
                isLive={isLive}
              />
            </div>
            <div
              class={`${value !== 4 && "hidden"}  rounded-lg`}
              role="tabpanel"
            >
              <PointsTable
                page="singlelivepage"
                isLive={isLive}
                matchId={matchId}
              />
            </div>
            <div
              class={`${value !== 5 && "hidden"}  rounded-lg`}
              role="tabpanel"
            >
              <Info page="singlelivepage" isLive={isLive} matchId={matchId} />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DetailedMatch;
