import React, { useState, useEffect, useContext } from "react";
import wsContext from "../../Context/wsContext";
import { useSelector } from "react-redux";

// import { Link } from "react-router-dom";

export default function Example() {
  const ws = useContext(wsContext);
  const userData = useSelector((state) => state.user);
  const [socialLinks, setSocialLinks] = useState(false);
  const wsAction = "get_banner";
  const wsKey = "footer";

  useEffect(() => {
    if (userData && userData.user.master > 0 && userData.user.master !== null) {
      ws.register(wsAction, wsKey, function (data) {
        setSocialLinks({
          facebookId: data.facebook ? data.facebook : undefined,
          instagramId: data.instagram ? data.instagram : undefined,
          whatsapp: data.whatsapp ? data.whatsapp : undefined,
        });
      });
      ws.get(
        wsAction,
        undefined,
        "homepage",
        undefined,
        undefined,
        undefined,
        userData.user.master
      );
      return () => {
        ws.unregister(wsAction, wsKey);
      };
    }
  }, [userData]);

  const navigation = {
    social: [
      {
        name: "Facebook",
        href: `https://www.facebook.com/${socialLinks.facebookId}`,
        icon: (props) => (
          <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
            <path
              fillRule="evenodd"
              d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
              clipRule="evenodd"
            />
          </svg>
        ),
      },
      {
        name: "Instagram",
        href: `https://www.instagram.com/${socialLinks.instagramId}`,
        icon: (props) => (
          <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
            <path
              fillRule="evenodd"
              d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
              clipRule="evenodd"
            />
          </svg>
        ),
      },
      {
        name: "WhatsApp",
        href: `https://wa.me/91${socialLinks.whatsapp}`,
        icon: (props) => (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            // className="h-7 w-7"
            fill="currentColor"
            viewBox="0,0,256,256"
            {...props}
          >
            <g
              fill="#bcbcbc"
              fill-rule="nonzero"
              stroke="none"
              stroke-width="1"
              stroke-linecap="butt"
              stroke-linejoin="miter"
              stroke-miterlimit="10"
              stroke-dasharray=""
              stroke-dashoffset="0"
              font-family="none"
              font-weight="none"
              font-size="none"
              text-anchor="none"
            >
              <g transform="scale(10.66667,10.66667)">
                <path
                  fillRule="evenodd"
                  d="M12,2c-5.523,0 -10,4.477 -10,10c0,1.592 0.382,3.091 1.043,4.427l-1.005,4.019c-0.229,0.915 0.6,1.745 1.516,1.516l4.019,-1.005c1.336,0.661 2.835,1.043 4.427,1.043c5.523,0 10,-4.477 10,-10c0,-5.523 -4.477,-10 -10,-10z"
                  opacity="0.35"
                  clipRule="evenodd"
                ></path>
                <path d="M16.393,13.467c-0.809,-0.809 -2.121,-0.809 -2.93,0l-0.516,0.516l-2.93,-2.93l0.516,-0.516c0.809,-0.809 0.809,-2.121 0,-2.93c-0.809,-0.809 -2.121,-0.809 -2.93,0c-0.517,0.517 -0.7,1.239 -0.556,1.904c0.123,0.919 0.606,3.029 2.509,4.932c1.903,1.903 4.013,2.386 4.932,2.509c0.665,0.144 1.387,-0.039 1.904,-0.556c0.81,-0.809 0.81,-2.12 0.001,-2.929z"></path>
              </g>
            </g>
          </svg>
        ),
      },
    ],
  };

  return (
    <footer
      className="bg-website-background-color"
      aria-labelledby="footer-heading"
    >
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="mx-auto max-w-7xl px-6 pb-8 lg:px-8">
        <div className="mt-4 border-t border-slate-300/10 pt-8 md:flex md:items-center md:justify-between">
          <div className="flex space-x-6 md:order-2 justify-center">
            {navigation.social.map((item) => (
              <a
                target="_blank"
                key={item.name}
                href={item.href}
                className="text-gray-400 hover:text-gray-500"
              >
                <span className="sr-only">{item.name}</span>
                <item.icon className="h-6 w-6" aria-hidden="true" />
              </a>
            ))}
          </div>
          <div>
            <p className="mt-8 text-xs leading-5 text-slate-300 md:order-1 md:mt-0">
              &copy; 2021 EasyLiveLine, All rights reserved.
            </p>
            <p className="mt-2 text-sm leading-6 text-gray-400">
              For any query or complaint email us at below address, we will
              revert you within 24hrs.
            </p>
            <p className="mt-2 text-sm leading-6 text-gray-400">
              support@easyliveline.com
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}
