import { Link } from "react-router-dom";
import wsContext from "../../../Context/wsContext";
import React, { useState, useContext, useEffect } from "react";
import teams from "../../../teams.json";

function RecentMatches(props) {
  const ws = useContext(wsContext);
  const [recentMatches, setRecentMatches] = useState([]);
  // const [seriesList, setSeriesList] = useState([]);
  // const [page, setPage] = useState(10);
  const wsAction = "get_recent_matches";
  const wsKey = props.key;

  useEffect(() => {
    ws.register(wsAction, wsKey, function (data) {
      setRecentMatches(data.slice(0, 9));
    });

    ws.get(wsAction, undefined, "homepage");

    return () => {
      ws.unregister(wsAction, wsKey);
    };
  }, []);

  return (
    <React.Fragment>
      {recentMatches && recentMatches.toString() ? (
        <div className="flex w-1/3 flex-wrap lg:mx-2 lg:my-3">
          {/* <div className="my-4 flow-root">
            <h1 className="flex w-full items-center justify-center rounded-md border border-slate-600 bg-slate-900 px-4 py-2 text-sm font-medium text-slate-300 mb-4">
              Recent Matches
            </h1>
            <ul className="-my-5 divide-y divide-slate-700">
              {recentMatches.map((person) => (
                <li key={person.handle} className="py-4 mx-2 ">
                  <Link
                    key={person.match_id}
                    style={{ textDecoration: "none" }}
                    to={`/matchinfo/recent/${person.match_id}/${person.series_id}`}
                  >
                    <div className="flex items-center space-x-4">
                      <div className="flex-shrink-0 w-1/6">
                        <img
                          className="lg:h-16 lg:w-16 h-12 w-12 rounded-full"
                          src={teams[0][person.team_a]}
                          alt=""
                        />
                      </div>
                      <div className="min-w-0 flex-1 w-4/6">
                        <p className=" lg:text-base text-sm font-medium text-slate-300">
                          {`${person.team_a} v/s ${person.team_b}`}
                        </p>
                        <p className="text-sm font-medium text-gray-500">
                          {person.series}
                        </p>
                        <p className=" text-sm font-medium text-gray-500">
                          {`${person.match_date} - ${person.match_time}`}
                        </p>
                        <p
                          className={`lg:text-base text-sm ${
                            person.result.includes("won")
                              ? "text-green-600"
                              : person.result.includes("Tied")
                              ? "text-orange-500"
                              : "text-red-600"
                          }`}
                        >
                          {person.result}
                        </p>
                      </div>
                      <div className="flex justify-end w-1/6">
                        <img
                          className="lg:h-16 lg:w-16 h-12 w-12 rounded-full"
                          src={teams[0][person.team_b]}
                          alt=""
                        />
                      </div>
                    </div>
                  </Link>
                </li>
              ))}
            </ul>
          </div> */}
          <ul className="flex flex-col gap-4 grow">
            {recentMatches.map((person) => (
              <Link
                key={person.match_id}
                style={{ textDecoration: "none" }}
                to={`/matchinfo/recent/${person.match_id}/${person.series_id}`}
              >
                <li
                  key={person.email}
                  className="divide-y divide-slate-600 rounded-lg bg-slate-900 shadow dark:hover:bg-gray-800"
                >
                  <div className="flex w-full items-center justify-between space-x-6 p-4">
                    <img
                      className="h-10 w-10 flex-shrink-0 rounded-full bg-gray-800"
                      // src={teams[0][person.team_a]}
                      src={
                        teams[0][`${person.team_a}`]
                          ? `//images.weserv.nl/?url=${
                              teams[0][`${person.team_a}`]
                            }`
                          : `//images.weserv.nl/?url=${person.team_a_img}`
                      }
                      alt="person.team_a"
                    />
                    <div className="flex flex-col justify-center items-center">
                      <div className="flex space-x-3">
                        <h3 className="text-sm font-medium text-slate-300">
                          {`${person.team_a} v/s ${person.team_b}`}
                        </h3>
                      </div>
                      <div className="">
                        <p className="mt-1 truncate text-sm text-gray-500">
                          {person.series}
                        </p>
                      </div>
                      <div className="flex mt-1">
                        <img
                          className="mr-2"
                          src={"https://crex.live/assets/icon/calender.svg"}
                          alt={person.match_time}
                        />
                        <p class="align-middle max-w-2xl text-sm leading-6 text-gray-400">
                          {`${person.match_date} - ${person.match_time}`}
                        </p>
                      </div>
                    </div>
                    <img
                      className="h-10 w-10 flex-shrink-0 rounded-full bg-gray-800"
                      src={
                        teams[0][`${person.team_b}`]
                          ? `//images.weserv.nl/?url=${
                              teams[0][`${person.team_b}`]
                            }`
                          : `//images.weserv.nl/?url=${person.team_b_img}`
                      }
                      alt="person.team_b"
                    />
                  </div>
                  <div>
                    <div className="-mt-px flex divide-x divide-slate-600 ">
                      <div className="-ml-px flex w-0 flex-1">
                        <p
                          className={` relative inline-flex w-0 flex-1 items-center justify-center rounded-br-lg border border-transparent py-2 text-sm font-medium ${
                            person.result.includes("won")
                              ? "text-green-600"
                              : person.result.includes("Tied")
                              ? "text-orange-500"
                              : "text-red-600"
                          }`}
                        >
                          {person.result}
                        </p>
                      </div>
                    </div>
                  </div>
                </li>
              </Link>
            ))}
          </ul>
        </div>
      ) : (
        <div className="w-1/3">
          <div className="flex flex-col animate-pulse">
            <div className="ml-4 mt-2 w-full">
              <h3 className="h-4 bg-gray-200 rounded-md dark:bg-gray-700 w-2/5"></h3>
              <ul className="mt-5 space-y-3">
                <li className="w-full h-4 bg-gray-200 rounded-md dark:bg-gray-700"></li>
                <li className="w-full h-4 bg-gray-200 rounded-md dark:bg-gray-700"></li>
                <li className="w-full h-4 bg-gray-200 rounded-md dark:bg-gray-700"></li>
                <li className="w-full h-4 bg-gray-200 rounded-md dark:bg-gray-700"></li>
              </ul>
            </div>
            <div className="ml-4 mt-2 w-full">
              <h3 className="h-4 bg-gray-200 rounded-md dark:bg-gray-700 w-2/5"></h3>
              <ul className="mt-5 space-y-3">
                <li className="w-full h-4 bg-gray-200 rounded-md dark:bg-gray-700"></li>
                <li className="w-full h-4 bg-gray-200 rounded-md dark:bg-gray-700"></li>
                <li className="w-full h-4 bg-gray-200 rounded-md dark:bg-gray-700"></li>
                <li className="w-full h-4 bg-gray-200 rounded-md dark:bg-gray-700"></li>
              </ul>
            </div>
            <div className="ml-4 mt-2 w-full">
              <h3 className="h-4 bg-gray-200 rounded-md dark:bg-gray-700 w-2/5"></h3>
              <ul className="mt-5 space-y-3">
                <li className="w-full h-4 bg-gray-200 rounded-md dark:bg-gray-700"></li>
                <li className="w-full h-4 bg-gray-200 rounded-md dark:bg-gray-700"></li>
                <li className="w-full h-4 bg-gray-200 rounded-md dark:bg-gray-700"></li>
                <li className="w-full h-4 bg-gray-200 rounded-md dark:bg-gray-700"></li>
              </ul>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}

export default RecentMatches;
