import { createStore } from "redux";

const userReducer = (
  state = { user: undefined, master: undefined },
  action
) => {
  if (action.type === "updateProfile") {
    return {
      user: action.payload,
    };
  }
  if (action.type === "updateMaster") {
    return {
      master: action.payload,
    };
  }
  return {
    user: state.user,
  };
};

const store = createStore(userReducer);

export default store;
