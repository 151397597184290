import React from "react";
// import { useSelector } from "react-redux";

import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import wsContext from "./Context/wsContext";
import { Provider } from "react-redux";
import store from "../src/store/fakeStore";

reportWebVitals();

const listeners = {};
let ws = null;
let currentState = {
  isLive: false,
  SingleID: undefined,
  page: undefined,
};

function SetupWebsocket(firstTime = false) {
  // ws = new WebSocket(
  //   `${window.location.origin.includes("https") ? "wss://backend." : "ws://"}${
  //     window.location.hostname
  //   }:${window.location.origin.includes("localhost") ? "3002" : "3001"}`
  // );
  ws = new WebSocket('wss://easyliveline.com/api/');

  ws.onopen = function () {
    console.log("Websocket connection opened");
    if (firstTime) {
      ReactDOM.createRoot(document.getElementById("root")).render(
        <React.Fragment>
          <Provider store={store}>
            <wsContext.Provider
              value={{
                isAuthenticated: false,
                register: registerListener,
                unregister: unregisterListener,
                get: getData,
              }}
            >
              <App />
            </wsContext.Provider>
          </Provider>
        </React.Fragment>
      );
      reportWebVitals();
    }
    if (!firstTime) {
      ws.send(JSON.stringify(currentState));
    }
  };

  ws.onmessage = async function (event) {
    if (event.data === "ping") {
      return ws.send("pong");
    } else if (event.data === "pong") {
      return ws.send("ping");
    }
    try {
      let parsedEvent = JSON.parse(event.data);
      let callbacks = listeners[parsedEvent.action];
      if (callbacks) {
        for (let callbackKey in callbacks) {
          if (callbacks.hasOwnProperty(callbackKey)) {
            await callbacks[callbackKey](parsedEvent.data);
          }
        }
      }
    } catch (e) {
      console.log(68, e.message, event.data);
    }
  };
  ws.onerror = function (error) {
    console.log(`Websocket error occurred: ${error.message}`);
    ws.close();
  };
  ws.onclose = function (event) {
    console.error(event.message);
    console.error(event);
    setTimeout(function () {
      SetupWebsocket();
    }, 1000);
  };
}

function registerListener(action, key, callback) {
  if (!listeners[action]) {
    listeners[action] = {};
  }
  listeners[action][key] = callback;
}
function unregisterListener(action, key) {
  if (listeners[action]) {
    delete listeners[action][key];
  }
}
let dataTimeouts = {};
function getData(
  action,
  ID,
  page,
  isLive,
  subpage,
  payload,
  userId,
  seriesID,
  type,
  streamingData
) {
  if (isLive) {
    currentState.isLive = isLive;
  } else {
    currentState.isLive = false;
  }
  if (page) {
    currentState.page = page;
  }
  if (ID) {
    currentState.SingleID = ID;
  } else {
    currentState.SingleID = undefined;
  }
  // console.log(currentState);
  if (ws && ws.readyState !== WebSocket.CLOSED) {
    ws.send(
      JSON.stringify({
        action: action,
        SingleID: ID,
        page: page,
        isLive: isLive,
        subpage: subpage ? subpage : undefined,
        payload: payload ? payload : undefined,
        userId: userId,
        seriesID: seriesID ? seriesID : undefined,
        type: type ? type : undefined,
        streamData: streamingData ? streamingData : undefined,
      })
    );
  } else {
    let dataTimeoutKey = page + "_" + action + "_" + ID + "_" + isLive;
    if (dataTimeouts[dataTimeoutKey]) {
      return;
    }
    dataTimeouts[dataTimeoutKey] = setTimeout(function () {
      dataTimeouts[dataTimeoutKey] = null;
      getData(action, ID, page, isLive);
    }, 1000);
  }
}
SetupWebsocket(true);
