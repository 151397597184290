// import Marquee from "react-fast-marquee";
import React, { useState, useEffect, useContext } from "react";
import wsContext from "../../Context/wsContext";
import { useSelector } from "react-redux";

function Banner(props) {
  const ws = useContext(wsContext);
  const userData = useSelector((state) => state.user);
  const [banner, setBanner] = useState(false);
  const wsAction = "get_banner";
  const wsKey = "header1";
  useEffect(() => {
    if (userData && userData.user.master > 0 && userData.user.master !== null) {
      ws.register(wsAction, wsKey, function (data) {
        data.image !== null &&
          data.image.formats &&
          data.image.formats.large &&
          setBanner(data.image.formats.large.url);
      });
      ws.get(
        wsAction,
        props.matchId,
        "singlelivepage-" + props.matchId,
        props.isLive,
        undefined,
        undefined,
        userData.user.master
      );
      return () => {
        setBanner(false);
        ws.unregister(wsAction, wsKey);
      };
    }
  }, [userData]);

  return (
    banner && (
      <div className="flex justify-center items-center">
        <img
          className="w-full lg:w-5/6"
          src={`https://admin.easyliveline.is${banner}`}
          alt="img-banner"
        />
      </div>
    )
  );
}

export default Banner;
