import React, { Fragment, useState, useContext } from "react";
import { Formik, Field, Form } from "formik";
import wsContext from "../../Context/wsContext";
import GlobalFilter from "./globalFilter";
import { useSelector } from "react-redux";
import { useTable, useSortBy, useGlobalFilter } from "react-table";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import uploadIcon from "../../upload.png";

const Masters = (props) => {
  const ws = useContext(wsContext);
  const userData = useSelector((state) => state.user);
  const [error, setError] = useState(undefined);
  const wsAction1 = "get_edit_response";
  const wsAction2 = "get_delete_response";
  const wsKey = "header";
  const [open, setOpen] = useState(false);
  const [upgrade, setUpgrade] = useState(false);
  const [userToEdit, setUserToEdit] = useState(false);
  const data = props.data;
  // const data = useMemo(() => props.data, [props.data]);

  function lastseen(date) {
    const lastseenDate = new Date(0);
    lastseenDate.setUTCSeconds(date / 1000);
    const timeDiff = Math.abs(new Date() - lastseenDate);
    let diffSec = Math.floor((timeDiff / 1000) % 60);
    let diffDays = Math.floor(timeDiff / 86400000); // days
    let diffHrs = Math.floor((timeDiff % 86400000) / 3600000); // hours
    let diffMins = Math.floor(((timeDiff % 86400000) % 3600000) / 60000); // minutes
    return diffDays !== 0
      ? `${diffDays} days ago`
      : `${diffHrs ? `${diffHrs} hrs` : ""} ${
          diffMins && diffHrs === 0 ? `${diffMins} mins` : ""
          // diffMins ? `${diffMins} min` : ""
        } ${
          diffSec && diffHrs === 0 && diffMins === 0 ? `${diffSec} secs` : ""
        } ago`;
  }

  function isExpired(date) {
    const date1 = new Date(date);
    console.log(date1);
    const date2 = new Date();
    console.log(date2);
    console.log(date1 > date2);
    return date1 >= date2;
  }

  function remainingDays(date) {
    const date1 = new Date(date);
    const date2 = new Date();
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  }

  const columns = [
    {
      Header: "ID",
      accessor: "id",
    },
    // {
    //   Header: "Name",
    //   accessor: `firstName lastName`,
    // },
    {
      Header: "Username",
      accessor: "username",
    },
    {
      Header: "Last Seen",
      accessor: "lastActive",
      Cell: (props) => (
        <span className="number">{props.value && lastseen(props.value)}</span>
      ),
    },
    {
      Header: "Role",
      accessor: "type",
    },
    {
      Header: "Plan",
      accessor: "subscription",
      Cell: (props) => (
        <span className="number">{props.value !== 0 && props.value}</span>
      ),
    },
    {
      Header: "Start",
      accessor: "startDate",
    },
    {
      Header: "End",
      accessor: "endDate",
      Cell: (props) => (
        <span
          className={`${
            isExpired(props.value) ? "text-green-400" : "text-red-400"
          }`}
        >
          {props.value}
        </span>
      ),
    },
    // {
    //   Header: "isExpired",
    //   Cell: (props) => (
    //     <span className="">
    //       {props.row.original.endDate && isExpired(props.row.original.endDate)
    //         ? "Active"
    //         : "Expired"}
    //     </span>
    //   ),
    // },
    {
      Header: "Days Left",
      Cell: (props) => (
        <span className="">
          {isExpired(props.row.original.endDate) &&
            `${remainingDays(props.row.original.endDate)} days`}
        </span>
      ),
    },
    {
      Header: "Master",
      accessor: "master",
      Cell: (props) => (
        <span className="number">{props.value !== 0 && props.value}</span>
      ),
    },
    {
      Header: "Max Pts",
      accessor: "points",
      Cell: (props) => (
        <span className="number">{props.value !== 0 && props.value}</span>
      ),
    },
    {
      Header: "Used Pts",
      accessor: "usedPoints",
    },
  ];

  console.log(data);

  function userDelete(id) {
    ws.register(wsAction2, wsKey, function (data) {
      if (data !== 200) {
        setError("Something Went Wrong");
      } else {
        setError("Success");
        window.location.reload(false);
      }
    });
    ws.get(wsAction2, undefined, "header", undefined, undefined, undefined, id);
    return () => {
      setError(null);
      ws.unregister(wsAction2, wsKey);
    };
  }

  const tableHooks = (hooks) => {
    hooks.visibleColumns.push((columns) => [
      ...columns,
      {
        id: "Edit",
        Header: "Edit",
        Cell: ({ row }) => (
          <button
            className="text-xsm lg:text-sm text-white w-full flex items-center justify-center hover:bg-slate-800 font-medium px-2 py-1 text-center"
            type="button"
            onClick={() => {
              setOpen(true);
              setUserToEdit(row.values);
            }}
          >
            <svg
              aria-hidden="true"
              class="mr-1 -ml-1 lg:w-5 lg:h-5 w-3 h-3"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z"></path>
              <path
                fill-rule="evenodd"
                d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
                clip-rule="evenodd"
              ></path>
            </svg>
            Edit
          </button>
        ),
      },
      {
        id: "Upgrade",
        Header: "Upgrade",
        Cell: ({ row }) => (
          <button
            className="text-xsm lg:text-sm text-white w-full flex items-center justify-center hover:bg-slate-800 font-medium px-2 py-1 text-center"
            type="button"
            onClick={() => {
              setUserToEdit(row.values);
              setUpgrade(true);
            }}
          >
            <img
              src={uploadIcon}
              alt="uploadicon"
              class="mr-1 -ml-1 lg:w-5 lg:h-5 w-3 h-3"
            />
            Upgrade
          </button>
        ),
      },
    ]);
  };

  const tableInstance = useTable(
    { columns, data, autoResetHiddenColumns: false },
    useGlobalFilter,
    tableHooks,
    useSortBy
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    preGlobalFilteredRows,
    setGlobalFilter,
    state,
  } = tableInstance;
  return (
    <>
      <Transition.Root show={upgrade} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => {
            setUpgrade(false);
            setError(undefined);
            setUserToEdit(false);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="backdrop-blur-sm fixed inset-0 hidden bg-gray-500 bg-opacity-75 transition-opacity md:block" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className=" flex min-h-full items-stretch justify-center text-center md:items-center md:px-2 lg:px-4">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
                enterTo="opacity-100 translate-y-0 md:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 md:scale-100"
                leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
              >
                <Dialog.Panel className="flex w-full transform text-left text-base transition md:my-8 md:max-w-2xl md:px-4 lg:max-w-4xl">
                  <div className="rounded-lg relative flex w-full items-center overflow-hidden bg-slate-900 px-4 pb-8 pt-14 shadow-2xl sm:px-6 sm:pt-8 md:p-6 lg:p-8">
                    <button
                      type="button"
                      className="absolute right-4 top-4 text-gray-400 hover:text-gray-500 sm:right-6 sm:top-8 md:right-6 md:top-6 lg:right-8 lg:top-8"
                      onClick={() => {
                        setUpgrade(false);
                        setError(undefined);
                        setUserToEdit(false);
                      }}
                    >
                      <span className="sr-only text-black">Close</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                    <Formik
                      initialValues={{
                        id: userToEdit.id,
                        subscription: 0,
                      }}
                      onSubmit={async (values) => {
                        let currentPoints =
                          userData.user.points - userData.user.usedPoints;
                        let pointsToUse = 0;
                        if (
                          values.subscription === 0 ||
                          values.subscription === 2
                        ) {
                          pointsToUse = 0;
                        } else {
                          pointsToUse = values.subscription / 31;
                        }
                        if (
                          (userData.user.type === "Master" &&
                            currentPoints >= pointsToUse) ||
                          userData.user.type === "Owner"
                        ) {
                          ws.register(wsAction1, wsKey, function (data) {
                            if (data.status === 400) {
                              setError(data.message);
                            } else {
                              setError("Success");
                              if (userData.user.type === "Master") {
                                ws.register(wsAction1, wsKey, function (data) {
                                  window.location.reload(false);
                                });
                                ws.get(
                                  wsAction1,
                                  undefined,
                                  "header",
                                  undefined,
                                  undefined,
                                  {
                                    id: userData.user.id,
                                    usedPoints:
                                      userData.user.usedPoints + pointsToUse,
                                  }
                                );
                                return () => {
                                  setError(null);
                                  ws.unregister(wsAction1, wsKey);
                                };
                              } else {
                                window.location.reload(false);
                              }
                            }
                          });
                          ws.get(
                            wsAction1,
                            undefined,
                            "header",
                            undefined,
                            undefined,
                            values
                          );
                          return () => {
                            setError(null);
                            ws.unregister(wsAction1, wsKey);
                          };
                        } else {
                          setError(
                            `No Points Available (Max Points: ${
                              userData.user.points
                            } / Used Points: ${
                              userData.user.usedPoints
                            } / Remaining Points: ${
                              userData.user.points - userData.user.usedPoints
                            } / Using Points: ${pointsToUse})`
                          );
                        }
                      }}
                    >
                      <Form className="flex flex-col w-full justify-center items-center">
                        {error && (
                          <h1
                            className={` p-2 rounded-lg text-slate-900 ${
                              error !== "Success"
                                ? "bg-red-500"
                                : "bg-green-500"
                            }`}
                          >
                            {error !== "Success"
                              ? error
                              : error === "Success"
                              ? "Action Successfull"
                              : ""}
                          </h1>
                        )}
                        <div className="w-full flex justify-center items-center gap-2">
                          <div className="w-1/3 flex flex-col">
                            <label
                              className="text-slate-300 my-2"
                              htmlFor="subscription"
                            >
                              {`Upgrading Subscription: ${userToEdit.username}`}
                            </label>
                            <Field as="select" name="subscription">
                              <option value="0">Free</option>
                              <option value="31">1 Month</option>
                              <option value="62">2 Months</option>
                              <option value="93">3 Months</option>
                            </Field>
                          </div>
                        </div>

                        <div className="w-full flex justify-center items-center self-center gap-2 pt-4">
                          <div className="w-1/3">
                            <button
                              type="submit"
                              class="inline-flex w-full items-center text-white justify-center bg-green-600 hover:bg-green-700 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-green-500 dark:hover:bg-green-600 dark:focus:ring-green-900"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="w-5 h-5 mr-1.5 -ml-1"
                                x="0px"
                                y="0px"
                                width="100"
                                height="100"
                                viewBox="0 0 24 24"
                              >
                                <path d="M22.997,15h-7c-0.552,0-1,0.448-1,1v6.5c0,0.828,0.672,1.5,1.5,1.5h6c0.828,0,1.5-0.672,1.5-1.5V16	C23.997,15.448,23.549,15,22.997,15z M21.737,17.505c0,1.238-1.007,2.245-2.244,2.245c-1.238,0-2.245-1.007-2.245-2.245V17	c0-0.414,0.336-0.75,0.75-0.75s0.75,0.336,0.75,0.75v0.505c0,0.411,0.334,0.745,0.745,0.745c0.41,0,0.744-0.334,0.744-0.745V17	c0-0.414,0.336-0.75,0.75-0.75s0.75,0.336,0.75,0.75V17.505z"></path>
                                <path
                                  d="M21.038,16.259C21.648,14.966,22,13.525,22,12c0-5.523-4.477-10-10-10 C6.477,2,2,6.477,2,12s4.477,10,10,10c2.512,0,4.802-0.933,6.558-2.463c0.287,0.133,0.603,0.213,0.94,0.213 c1.237,0,2.244-1.007,2.244-2.245V17C21.742,16.602,21.429,16.284,21.038,16.259z M19.901,18.115 c0.102-0.131,0.204-0.263,0.299-0.4C20.149,17.883,20.044,18.02,19.901,18.115z"
                                  opacity=".35"
                                ></path>
                                <path d="M16.707,11.293l-4-4c-0.391-0.391-1.023-0.391-1.414,0l-4,4C7.098,11.488,7,11.744,7,12s0.098,0.512,0.293,0.707 c0.391,0.391,1.023,0.391,1.414,0L11,10.414c0,2.588,0,6.358,0,6.586c0,0.552,0.448,1,1,1s1-0.448,1-1c0-0.228,0-3.998,0-6.586 l2.293,2.293c0.391,0.391,1.023,0.391,1.414,0C17.098,12.316,17.098,11.684,16.707,11.293z"></path>
                              </svg>
                              Upgrade Plan
                            </button>
                          </div>
                        </div>
                      </Form>
                    </Formik>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10 "
          onClose={() => {
            setOpen(false);
            setError(undefined);
            setUserToEdit(false);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="backdrop-blur-sm fixed inset-0 hidden bg-gray-500 bg-opacity-75 transition-opacity md:block" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className=" flex min-h-full items-stretch justify-center text-center md:items-center md:px-2 lg:px-4">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
                enterTo="opacity-100 translate-y-0 md:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 md:scale-100"
                leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
              >
                <Dialog.Panel className="flex w-full transform text-left text-base transition md:my-8 md:max-w-2xl md:px-4 lg:max-w-4xl">
                  <div className="rounded-lg relative flex w-full items-center overflow-hidden bg-slate-900 px-4 pb-8 pt-14 shadow-2xl sm:px-6 sm:pt-8 md:p-6 lg:p-8">
                    <button
                      type="button"
                      className="absolute right-4 top-4 text-gray-400 hover:text-gray-500 sm:right-6 sm:top-8 md:right-6 md:top-6 lg:right-8 lg:top-8"
                      onClick={() => {
                        setOpen(false);
                        setError(undefined);
                        setUserToEdit(false);
                      }}
                    >
                      <span className="sr-only text-black">Close</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                    <Formik
                      initialValues={{
                        id: userToEdit.id,
                        firstName: userToEdit.firstName,
                        lastName: userToEdit.lastName,
                        // email: userToEdit.email,
                        mobile: userToEdit.mobile,
                        password: "",
                        type: userToEdit.type,
                        username: userToEdit.username,
                        master: userToEdit.master,
                        points: userToEdit.points,
                      }}
                      onSubmit={async (values) => {
                        ws.register(wsAction1, wsKey, function (data) {
                          if (data.status === 400) {
                            setError(data.message);
                          } else setError("Success");
                        });
                        ws.get(
                          wsAction1,
                          undefined,
                          "header",
                          undefined,
                          undefined,
                          values
                        );
                        return () => {
                          setError(null);
                          ws.unregister(wsAction1, wsKey);
                        };
                      }}
                    >
                      <Form className="flex flex-col w-full justify-center items-center">
                        {error && (
                          <h1
                            className={` p-2 rounded-lg text-slate-900 ${
                              error !== "Success"
                                ? "bg-red-500"
                                : "bg-green-500"
                            }`}
                          >
                            {error !== "Success"
                              ? error
                              : error === "Success"
                              ? "Action Successfull"
                              : ""}
                          </h1>
                        )}
                        <div className="w-full flex gap-2">
                          <div className="w-1/2 flex flex-col">
                            <label
                              className="text-slate-300"
                              htmlFor="firstName"
                            >
                              First Name
                            </label>
                            <Field
                              className="rounded-lg"
                              id="firstName"
                              name="firstName"
                              placeholder={userToEdit.firstName}
                              type="text"
                            />
                          </div>
                          <div className="w-1/2 flex flex-col">
                            <label
                              className="text-slate-300"
                              htmlFor="lastName"
                            >
                              Last Name
                            </label>
                            <Field
                              className="rounded-lg"
                              id="lastName"
                              name="lastName"
                              placeholder={userToEdit.lastName}
                              type="text"
                            />
                          </div>
                        </div>
                        <div className="w-full flex gap-2">
                          <div className="flex flex-col w-1/2">
                            <label
                              className="text-slate-300"
                              htmlFor="password"
                            >
                              Password
                            </label>
                            <Field
                              className="rounded-lg"
                              id="password"
                              name="password"
                              placeholder="Password"
                              type="password"
                            />
                          </div>
                          <div className="flex flex-col w-1/2">
                            <label
                              className="text-slate-300"
                              htmlFor="password"
                            >
                              Mobile
                            </label>
                            <Field
                              className="rounded-lg"
                              id="mobile"
                              name="mobile"
                              placeholder="9876543210"
                              type="tel"
                            />
                          </div>
                        </div>

                        <div className="w-full flex gap-2">
                          {userData.user.type === "Owner" && (
                            <>
                              <div className="flex flex-col w-1/3">
                                <label
                                  className="text-slate-300"
                                  htmlFor="type"
                                >
                                  Role
                                </label>
                                <Field
                                  as="select"
                                  name="type"
                                  className="rounded-lg"
                                >
                                  <option value="Free">Free</option>
                                  <option value="Master">Master</option>
                                  <option value="Subscriber">Subscriber</option>
                                </Field>
                              </div>
                              <div className="flex flex-col w-1/3">
                                <label
                                  className="text-slate-300"
                                  htmlFor="master"
                                >
                                  Master
                                </label>
                                <Field
                                  className="rounded-lg"
                                  as="select"
                                  name="master"
                                >
                                  <option value="0">Null</option>;
                                  {props.master.map((master) => {
                                    return (
                                      <option
                                        value={master.id}
                                      >{`${master.firstName} ${master.lastName}`}</option>
                                    );
                                  })}
                                </Field>
                              </div>
                              <div className="flex flex-col w-1/3">
                                <label
                                  className="text-slate-300"
                                  htmlFor="points"
                                >
                                  Points
                                </label>
                                <Field
                                  className="rounded-lg"
                                  id="points"
                                  name="points"
                                  placeholder="Ex: 12"
                                  type="number"
                                />
                              </div>
                            </>
                          )}
                        </div>
                        <div className="w-full flex self-center gap-2 pt-4">
                          <div className="w-1/2">
                            <button
                              type="submit"
                              class="w-full  text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                            >
                              Submit
                              <svg
                                aria-hidden="true"
                                class="w-5 h-5 ml-2 -mr-1"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                                  clip-rule="evenodd"
                                ></path>
                              </svg>
                            </button>
                          </div>
                          {userData.user.type === "Owner" && (
                            <div className="w-1/2">
                              <button
                                onClick={() => {
                                  userDelete(userToEdit.id);
                                }}
                                type="button"
                                class="inline-flex w-full items-center text-white justify-center bg-red-600 hover:bg-red-700 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-red-500 dark:hover:bg-red-600 dark:focus:ring-red-900"
                              >
                                <svg
                                  aria-hidden="true"
                                  class="w-5 h-5 mr-1.5 -ml-1"
                                  fill="currentColor"
                                  viewBox="0 0 20 20"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                                    clip-rule="evenodd"
                                  ></path>
                                </svg>
                                Delete
                              </button>
                            </div>
                          )}
                        </div>
                      </Form>
                    </Formik>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <div className="break-normal [&::-webkit-scrollbar]:hidden overflow-x-auto object-right whitespace-nowrap text-slate-50 rounded-lg text-clip overflow-hidden">
        <GlobalFilter
          preGlobalFilteredRows={preGlobalFilteredRows}
          setGlobalFilter={setGlobalFilter}
          globalFilter={state.globalFilter}
        />
        <table className="bg-slate-900 w-full " {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    className="text-slate-300 bg-gray-700 justify-center items-center"
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {column.render("Header")}
                    {column.isSorted ? (column.isSortedDesc ? "▼" : "▲") : ""}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, idx) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  className={`${idx % 2 !== 0 && "bg-slate-700"}`}
                >
                  {row.cells.map((cell) => {
                    return (
                      <td
                        className="text-slate-300 text-center border-2 border-gray-800 "
                        {...cell.getCellProps()}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Masters;
