import { Fragment, useContext, useEffect, useState } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import wsContext from "../../Context/wsContext";
import { useSelector } from "react-redux";
import Cookies from "universal-cookie";
import jwt_decode from "jwt-decode";
import { useDispatch } from "react-redux";
// import Strapi from "strapi-sdk-js";
import axios from "axios";
import Logo from "../../Red Modern Label Logo.png";

const navigation = [
  { name: "Home", href: "/", current: false },
  { name: "Series", href: "/cricket/series", current: false },
  { name: "Fixtures", href: "/cricket/fixtures", current: false },
  // { name: "Multistream", href: "/multistream", current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Example() {
  const ws = useContext(wsContext);
  const dispatch = useDispatch();
  const cookies = new Cookies();
  const userData = useSelector((state) => state.user);
  const [aria, setAria] = useState(0);
  const wsAction1 = "get_cookie_response";
  const wsKey = "header";
  let decoded;
  if (cookies.get("authtoken")) {
    try {
      decoded = jwt_decode(cookies.get("authtoken"));
      ws.isAuthenticated = true;
    } catch (e) {
      cookies.remove("authtoken");
      ws.isAuthenticated = false;
    }
  }

  useEffect(() => {
    if (decoded && userData) {
      let data = JSON.stringify({
        lastActive: `${new Date().getTime()}`,
      });

      let config = {
        method: "put",
        maxBodyLength: Infinity,
        url: `https://admin.easyliveline.is/api/users/${userData.user.id}`,
        // url: `http://127.0.0.1:1337/api/users/${userData.user.id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer 94c73de21248e5e2a1c647d95361b40142e72c7d0d32bf9f521d92a4138c40a6f6b7d95d80df147a34eab667b633fafbc4086375652e80ef9b36dbb47712a2e8252b9929861b761244d2ddbf880aa2ea45f8f83dd32752302506e2b858f1082fc0ce892c3b7c66f1d6cb8f7199072a1df04beca5e7b765ae2a1c1ab36664772b",
        },
        data: data,
      };

      axios.request(config).catch((error) => {
        console.log(110, error);
      });
    }
  }, [userData, decoded]);

  //--------------Strapi SDK --------------------//

  // const strapi = new Strapi({
  //   url: "http://localhost:1337",
  //   prefix: "/api",
  //   store: {
  //     key: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MjksImlhdCI6MTY3ODk1NTI3NiwiZXhwIjoxNjgxNTQ3Mjc2fQ.GoY0cmwkw7UW63C4R9RfFFnINeYidPGy_5TC6zp0kiw",
  //     useLocalStorage: false,
  //     cookieOptions: { path: "/" },
  //   },
  //   axiosOptions: {},
  // });

  // const tryFunc = async () => {
  //   const { user, jwt } = await strapi.login({
  //     identifier: "mohit40@gmail.com",
  //     password: "22141714",
  //   });
  //   console.log("Strapi", user, jwt);
  // };

  // tryFunc();

  //------------Strapi SDK --------------------//

  const updateProfile = (data) => {
    dispatch({ type: "updateProfile", payload: data });
  };

  const signOut = () => {
    cookies.remove("authtoken");
    ws.isAuthenticated = false;
    updateProfile(null);
  };

  useEffect(() => {
    if (decoded) {
      ws.register(wsAction1, wsKey, async function (data) {
        if (data) {
          data = {
            user: data,
          };
          updateProfile(data);
          ws.isAuthenticated = true;
        } else {
          ws.isAuthenticated = false;
        }
      });
      ws.get(
        wsAction1,
        undefined,
        "homepage",
        undefined,
        undefined,
        decoded.id
      );
      return () => {
        ws.unregister(wsAction1, wsKey);
      };
    }
  }, []);
  // console.log(ws.isAuthenticated);

  // useEffect(() => {
  //   if (
  //     userData &&
  //     (userData.user.type === "Subscriber" ||
  //       userData.user.type === "Owner" ||
  //       userData.user.type === "Host")
  //   ) {
  //     navigation[3] = {
  //       name: "Multistream",
  //       href: "/multistream",
  //       current: false,
  //     };
  //   }
  // }, [userData]);

  return (
    <Disclosure as="nav" className="bg-website-background-color">
      {({ open }) => (
        <>
          <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8 ">
            <div className="relative flex h-16 items-center justify-between">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                <div className="flex flex-shrink-0 items-center">
                  <Link to={"/"}>
                    <img
                      className="block h-6 w-auto lg:hidden mr-36"
                      src={Logo}
                      alt="easyliveline"
                    />
                  </Link>
                  <Link to={"/"}>
                    <img
                      className="hidden h-8 w-auto lg:block"
                      src={Logo}
                      alt="easyliveline"
                    />
                  </Link>
                </div>
                <div className="hidden sm:ml-6 sm:block">
                  <div className="flex space-x-4">
                    {navigation.map((item, index) => (
                      <Link
                        key={index}
                        to={item.href}
                        onClick={() => {
                          setAria(index);
                        }}
                        className={classNames(
                          aria === index
                            ? "bg-gray-900 text-white"
                            : "text-gray-300 hover:bg-gray-700 hover:text-white",
                          "px-3 py-2 rounded-md text-sm font-medium"
                        )}
                        aria-current={aria === index ? "page" : undefined}
                      >
                        {item.name}
                      </Link>
                    ))}
                  </div>
                </div>
              </div>
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                {/* <button
                  type="button"
                  className="rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                >
                  <span className="sr-only">View notifications</span>
                  <BellIcon className="h-6 w-6" aria-hidden="true" />
                </button> */}

                {/* Profile dropdown */}
                {ws.isAuthenticated ? (
                  <Menu as="div" className="relative ml-3">
                    <div>
                      <Menu.Button className="flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                        <span className="sr-only">Open user menu</span>
                        <img
                          className="h-8 w-8 rounded-full"
                          src="https://img.icons8.com/external-bearicons-blue-bearicons/64/null/external-user-essential-collection-bearicons-blue-bearicons.png"
                          alt="profile-img"
                        />
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <Menu.Item>
                          {({ active }) => (
                            <p
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm font-semibold text-gray-700"
                              )}
                            >
                              {userData &&
                                `${userData.user.firstName} ${userData.user.lastName}`}
                            </p>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              to={"/myaccount"}
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm text-gray-700"
                              )}
                            >
                              My Account
                            </Link>
                          )}
                        </Menu.Item>

                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              to={"/login"}
                              onClick={signOut}
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm text-gray-700"
                              )}
                            >
                              Sign Out
                            </Link>
                          )}
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                ) : (
                  <div className="flex  items-center md:ml-12 ">
                    {/* <Link
                      to={"/login"}
                      className="text-base font-medium text-gray-500 hover:text-slate-300"
                    >
                      Login
                    </Link>
                    <Link
                      to={"/signup"}
                      className="lg:ml-8 ml-4 inline-flex items-center justify-center rounded-md border border-transparent  px-4 py-1 text-base font-medium text-white shadow-sm lg:hover:bg-slate-700"
                    >
                      Sign up
                    </Link> */}
                  </div>
                )}
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="space-y-1 px-2 pt-2 pb-3">
              {navigation.map((item, index) => (
                <Disclosure.Button
                  key={index}
                  onClick={() => {
                    // item.current = true;
                    // console.log(index);
                    setAria(index);
                  }}
                  as="a"
                  href={item.href}
                  className={classNames(
                    aria === index
                      ? "bg-gray-900 text-white"
                      : "text-gray-300 hover:bg-gray-700 hover:text-white",
                    "block px-3 py-2 rounded-md text-base font-medium"
                  )}
                  aria-current={aria === index ? "page" : undefined}
                >
                  {item.name}
                </Disclosure.Button>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
