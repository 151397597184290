import React, { useContext, useState } from "react";
import { useSelector } from "react-redux";
import { Formik, Field, Form } from "formik";
import wsContext from "../../Context/wsContext";

const UserCreatingForm = (props) => {
  const ws = useContext(wsContext);
  const userData = useSelector((state) => state.user);
  const [error, setError] = useState(undefined);
  const wsAction1 = "get_user_creation_response";
  const wsAction2 = "get_edit_response";
  const wsKey = "header";

  // const showPointsHandler = (props) => {
  //   if (props === 1) {
  //     setShowPoints(true);
  //   }
  // };
  // console.log(19, userData);
  return (
    <div className="flex flex-col">
      {/* <h1 className="text-slate-300">Sign Up</h1> */}
      {error && (
        <h1
          className={`text-slate-300 p-2 w-5/6 self-center ${
            error !== "Success" ? "bg-red-500" : "bg-green-500"
          }`}
        >
          {error !== "Success"
            ? error
            : error === "Success"
            ? "Successfully Created"
            : ""}
        </h1>
      )}
      <Formik
        // enableReinitialize={true}
        initialValues={{
          firstName: "",
          lastName: "",
          email: "",
          password: "",
          mobile: "",
          type: "Subscriber",
          username: "",
          subscription: userData.user.type === "Owner" ? 0 : 31,
          master: userData.user.type === "Master" ? userData.user.id : 0,
          points: undefined,
        }}
        onSubmit={async (values, { resetForm }) => {
          let currentPoints = userData.user.points - userData.user.usedPoints;
          let pointsToUse = 0;

          if (values.subscription == 0 || values.subscription == 2) {
            pointsToUse = 0;
          } else {
            pointsToUse = values.subscription / 31;
          }
          // console.log(22, values, null, 2);
          // console.log(54, pointsToUse, currentPoints);
          if (
            (userData.user.type === "Master" && currentPoints >= pointsToUse) ||
            userData.user.type === "Owner"
          ) {
            ws.register(wsAction1, wsKey, function (data) {
              // console.log(32, data);
              if (data.status === 400) {
                setError(data.message);
              } else {
                setError("Success");
                resetForm({
                  values: {
                    firstName: "",
                    lastName: "",
                    email: "",
                    password: "",
                    mobile: "",
                    type: "Subscriber",
                    username: "",
                    subscription: userData.user.type === "Owner" ? 0 : 31,
                    master:
                      userData.user.type === "Master" ? userData.user.id : 0,
                    points: 0,
                  },
                });
                if (userData.user.type === "Master") {
                  ws.register(wsAction2, wsKey, function (data) {
                    // console.log(87, data);
                    window.location.reload(false);
                  });
                  ws.get(wsAction2, undefined, "header", undefined, undefined, {
                    id: userData.user.id,
                    usedPoints: userData.user.usedPoints + pointsToUse,
                  });
                  return () => {
                    setError(null);
                    ws.unregister(wsAction2, wsKey);
                  };
                } else {
                  // window.location.reload(false);
                }
              }
            });
            ws.get(
              wsAction1,
              undefined,
              props.page,
              undefined,
              undefined,
              values
            );
            return () => {
              setError(null);
              ws.unregister(wsAction1, wsKey);
            };
          } else {
            setError(
              `No Points Available (Max Points: ${
                userData.user.points
              } / Used Points: ${
                userData.user.usedPoints
              } / Remaining Points: ${
                userData.user.points - userData.user.usedPoints
              } / Using Points: ${pointsToUse})`
            );
          }
        }}
      >
        <Form className="flex flex-col w-5/6 self-center">
          <div className="flex w-full gap-2">
            <div className="flex flex-col w-1/2">
              <label className="text-slate-300" htmlFor="firstName">
                First Name
              </label>
              <Field
                id="firstName"
                name="firstName"
                placeholder="Ashok"
                type="text"
                className="bg-slate-900 text-slate-300"
              />
            </div>
            <div className="flex flex-col w-1/2">
              <label className="text-slate-300" htmlFor="lastName">
                Last Name
              </label>
              <Field
                id="lastName"
                name="lastName"
                placeholder="Kumar"
                type="text"
                className="bg-slate-900 text-slate-300"
              />
            </div>
          </div>
          <div className="flex w-full gap-2">
            <div className="flex flex-col w-1/2">
              <label className="text-slate-300" htmlFor="email">
                Username
              </label>
              <Field
                id="username"
                name="username"
                placeholder="janedoe1995"
                type="text"
                className="bg-slate-900 text-slate-300"
              />
            </div>
            <div className="flex flex-col w-1/2">
              <label className="text-slate-300" htmlFor="password">
                Password
              </label>
              <Field
                id="password"
                name="password"
                placeholder="Password"
                type="password"
                className="bg-slate-900 text-slate-300"
              />
            </div>
          </div>
          <div className="flex w-full gap-2">
            <div className="flex flex-col w-1/2">
              <label className="text-slate-300" htmlFor="password">
                Mobile
              </label>
              <Field
                id="mobile"
                name="mobile"
                placeholder="9876543210"
                type="tel"
                className="bg-slate-900 text-slate-300"
              />
            </div>
            <div className="flex flex-col w-1/2">
              <label className="text-slate-300" htmlFor="subscription">
                Subscription
              </label>
              <Field
                as="select"
                name="subscription"
                className="bg-slate-900 text-slate-300"
              >
                {userData.user.type === "Owner" && (
                  <option value="0">Free</option>
                )}
                <option value="2">2 Days Demo</option>
                <option value="31">1 Month</option>
                <option value="62">2 Months</option>
                <option value="93">3 Months</option>
              </Field>
            </div>
          </div>
          {userData.user.type === "Owner" && (
            <div className=" flex w-full gap-2">
              <div className="flex flex-col w-1/3">
                <label className="text-slate-300" htmlFor="type">
                  Role
                </label>
                <Field
                  as="select"
                  name="type"
                  className="bg-slate-900 text-slate-300"
                >
                  <option value="Free">Free</option>
                  <option value="Master">Master</option>
                  <option value="Subscriber">Subscriber</option>
                </Field>
              </div>
              <div className="flex flex-col w-1/3">
                <label className="text-slate-300" htmlFor="master">
                  Master
                </label>
                <Field
                  as="select"
                  name="master"
                  className="bg-slate-900 text-slate-300"
                >
                  <option value="0">Null</option>;
                  {props.master.map((master) => {
                    return (
                      <option
                        value={master.id}
                      >{`${master.firstName} ${master.lastName}`}</option>
                    );
                  })}
                </Field>
              </div>
              <div className="flex flex-col w-1/3">
                <label
                  className={`
             text-slate-300`}
                  htmlFor="points"
                >
                  Points
                </label>
                <Field
                  className="bg-slate-900 text-slate-300"
                  id="points"
                  name="points"
                  placeholder="Ex: 12"
                  type="number"
                />
              </div>
            </div>
          )}
          <button
            type="submit"
            class="text-white text-xsm md:text-sm bg-blue-700 hover:bg-blue-800 my-2 w-1/4 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            Submit
            <svg
              aria-hidden="true"
              class="w-5 h-5 ml-2 -mr-1"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </button>
        </Form>
      </Formik>
    </div>
  );
};

export default UserCreatingForm;
