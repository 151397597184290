import React, { Suspense } from "react";
import App2 from "./App2";
import ErrorBoundary from "./Components/UI/ErrorBoundary";
import Footer from "./Components/UI/footer";
import Header from "./Components/UI/trialHeader";
import "./App.css";
import SeriesList from "./Components/Cricket/Series/Series";
import MatchInfo from "./Components/Cricket/SingleMatchDetailedPage/MatchInfo";
// import SingleMatch from "./Components/Cricket/SingleMatchDetailedPage/Single_Match_info";
import SingleSeries from "./Components/Cricket/Series/DetailedSeries";
import DetailedNews from "./Components/Cricket/News/detailed_news";
import { BrowserRouter, Routes, Route } from "react-router-dom";
// import FrontPage from "./Components/Cricket/FrontPage/front_page";
import Signin from "./Components/UI/signin";
import Signup from "./Components/UI/signup";
import ErrorPage from "./Components/UI/404";
// import ReactDOM from "react-dom";
import UpcomingSinglePage from "../src/Components/Cricket/UpcommingMatches/UpcomingSinglePage";
import Gridcard from "./Components/UI/accontinfo";
import Mas from "../src/Components/UI/userCreatingForm";
import Banner from "./Components/UI/scroll-banner";
// import { useNavigate } from "react-router-dom";
import Multistreming from "./Components/Cricket/MultiStreaming/multistreming";

// import { Route } from "react-router-dom";

function App() {
  // const navigate = useNavigate();
  return (
    <div className="flex min-h-screen flex-col overflow-x-hidden">
      <BrowserRouter>
        <Header />
        <Banner />
        <main className="my-0 grow bg-website-background-color">
          <ErrorBoundary
            fallback={
              <div>
                <p className="text-slate-300">Unable to fetch data to show.</p>
                {/* <button
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  Click Me!
                </button> */}
              </div>
            }
          >
            <Suspense
              fallback={
                <div className="lg:mx-40 ">
                  <div className="flex animate-pulse">
                    <div className="ml-4 mt-2 w-full">
                      <ul className="mt-5 space-y-3">
                        <li className="w-full h-4 bg-gray-200 rounded-md dark:bg-gray-700"></li>
                        <li className="w-full h-4 bg-gray-200 rounded-md dark:bg-gray-700"></li>
                        <li className="w-full h-4 bg-gray-200 rounded-md dark:bg-gray-700"></li>
                        <li className="w-full h-4 bg-gray-200 rounded-md dark:bg-gray-700"></li>
                        <li className="w-full h-4 bg-gray-200 rounded-md dark:bg-gray-700"></li>
                        <li className="w-full h-4 bg-gray-200 rounded-md dark:bg-gray-700"></li>
                        <li className="w-full h-4 bg-gray-200 rounded-md dark:bg-gray-700"></li>
                        <li className="w-full h-4 bg-gray-200 rounded-md dark:bg-gray-700"></li>
                        <li className="w-full h-4 bg-gray-200 rounded-md dark:bg-gray-700"></li>
                        <li className="w-full h-4 bg-gray-200 rounded-md dark:bg-gray-700"></li>
                        <li className="w-full h-4 bg-gray-200 rounded-md dark:bg-gray-700"></li>
                        <li className="w-full h-4 bg-gray-200 rounded-md dark:bg-gray-700"></li>
                        <li className="w-full h-4 bg-gray-200 rounded-md dark:bg-gray-700"></li>
                        <li className="w-full h-4 bg-gray-200 rounded-md dark:bg-gray-700"></li>
                        <li className="w-full h-4 bg-gray-200 rounded-md dark:bg-gray-700"></li>
                        <li className="w-full h-4 bg-gray-200 rounded-md dark:bg-gray-700"></li>
                        <li className="w-full h-4 bg-gray-200 rounded-md dark:bg-gray-700"></li>
                        <li className="w-full h-4 bg-gray-200 rounded-md dark:bg-gray-700"></li>
                        <li className="w-full h-4 bg-gray-200 rounded-md dark:bg-gray-700"></li>
                        <li className="w-full h-4 bg-gray-200 rounded-md dark:bg-gray-700"></li>
                      </ul>
                    </div>
                  </div>
                </div>
              }
            >
              <Routes>
                <Route path="/" element={<App2 />} />

                <Route path="/master" element={<Mas />} />
                {/* <Route path="/cricket" element={<App2 />} /> */}
                <Route
                  path="/cricket/series"
                  element={
                    <div>
                      <SeriesList page="series" />
                    </div>
                  }
                />
                <Route path="/multistream" element={<Multistreming />} />
                <Route
                  path="/news/:newsId"
                  element={<DetailedNews page="singlenews" />}
                />
                <Route
                  path="/cricket/series/:seriesId"
                  element={<SingleSeries page="serieslist" />}
                />
                <Route
                  path="matchinfo/:isLive/:matchId/:seriesId"
                  element={<MatchInfo page="singlelivepage" />}
                />
                <Route
                  path="/login"
                  element={
                    <div>
                      <Signin page="signinpage" />
                    </div>
                  }
                />
                <Route path="/signup" element={<Signup page="signuppage" />} />
                <Route
                  path="/myaccount"
                  element={<Gridcard page="signuppage" />}
                />
                <Route
                  path="/cricket/fixtures"
                  element={
                    <div>
                      <UpcomingSinglePage page="upcomingsinglepage" />
                    </div>
                  }
                />
                <Route path="*" element={<ErrorPage />} />
              </Routes>
            </Suspense>
          </ErrorBoundary>
        </main>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
