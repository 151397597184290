import wsContext from "../../../Context/wsContext";
import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import teams from "../../../teams.json";
// import Accordion from "@mui/material/Accordion";
// import AccordionSummary from "@mui/material/AccordionSummary";
// import AccordionDetails from "@mui/material/AccordionDetails";
// import Typography from "@mui/material/Typography";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const DetailedSeries = (props) => {
  const ws = useContext(wsContext);
  const params = useParams();
  let { seriesId } = useParams();
  const [detailedSeries, setDetailedSeries] = useState([]);
  const wsAction = "get_single_series";
  const wsKey = "single_series";

  useEffect(() => {
    ws.register(wsAction, wsKey, function (data) {
      setDetailedSeries(data);
    });
    ws.get(wsAction, seriesId, "single_series", undefined);
    return () => {
      ws.unregister(wsAction, wsKey);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return detailedSeries.map((series) => {
    return (
      <div className="w-3/4 flex flex-col mx-auto">
        <div className="flex w-full items-center justify-around p-2 m-1 border-solid border-2 border-slate-600 rounded-xl">
          <div className="flex items-center w-1/4 lg:gap-4 flex-col lg:flex-row">
            <img
              className="w-10 h-10 lg:w-20 lg:h-20"
              loading="lazy"
              src={`//images.weserv.nl/?url=${
                teams[0][`${series.team_a}`]
              }&fit=fill`}
              alt={detailedSeries.team_a_img}
            />
            <h1 className="place-self-center text-slate-300 text-xsm md:text-sm lg:text-base">
              {series.team_a}
            </h1>
          </div>
          <div className="flex w-1/2 flex-col items-center">
            <h1 className="border-solid border-2 border-gray-600 text-slate-300 rounded-md p-1 text-xsm md:text-sm lg:text-base">
              {series.match_type}
            </h1>
            <h1 className="font-semibold text-slate-300 text-xsm md:text-sm lg:text-base">
              {series.match_date} - {series.match_time}
            </h1>
            <h1 className="text-slate-300 text-xsm md:text-sm lg:text-base">
              {series.matchs}
            </h1>
          </div>
          <div className="flex items-center justify-start w-1/4 lg:gap-4 flex-col lg:flex-row">
            <img
              className="w-10 h-10 lg:w-20 lg:h-20"
              loading="lazy"
              src={`//images.weserv.nl/?url=${
                teams[0][`${series.team_b}`]
              }&w=80&h=80&fit=fill`}
              alt={detailedSeries.team_b_img}
            />
            <h1 className="place-self-center text-slate-300 text-xsm md:text-sm lg:text-base">
              {series.team_b}
            </h1>
          </div>
        </div>
      </div>
    );
  });
};

export default DetailedSeries;
