// import { Link } from "react-router-dom";
import wsContext from "../../../Context/wsContext";
import React, { useState, useContext, useEffect } from "react";
import teams from "../../../teams.json";

const UpcomingSinglePage = (props) => {
  const ws = useContext(wsContext);
  const [upcomingMatches, setUpcomingMatches] = useState([]);
  const wsAction = "get_all_fixtures";
  const wsKey = "allUpcomingMatches";

  let manFunc = async function (data) {
    let uniqueDates = [];
    let newArray = [];
    data.filter((o) => {
      if (uniqueDates.includes(o.date_wise)) return false;
      uniqueDates.push(o.date_wise);
      return true;
    });
    for (let x = 0; x < uniqueDates.length; x++) {
      newArray.push(uniqueDates[x]);
      const filterdata = await data.filter((o) => {
        return o.date_wise === uniqueDates[x];
      });
      newArray.push(filterdata);
    }
    setUpcomingMatches(newArray);
  };

  useEffect(() => {
    ws.register(wsAction, wsKey, function (data) {
      manFunc(data);
    });

    ws.get(wsAction, undefined, props.page);

    return () => {
      setUpcomingMatches(null);
      ws.unregister(wsAction, wsKey);
    };
  }, []);

  // console.log(upcomingMatches);

  return (
    <div>
      {upcomingMatches.toString() != [] ? (
        <div className="flex-col lg:w-5/6 mx-auto">
          <h1 className="ml-2 text-xsm text-slate-300">
            Timmings in Indian Standard Time (IST)
          </h1>
          {upcomingMatches.map((arr) => {
            if (typeof arr === "string") {
              return (
                <div className="flex text-white justify-start bg-slate-700 px-2">
                  <h1 className="text-base lg:text-xl py-1">{arr}</h1>
                </div>
              );
            } else {
              return arr.map((match) => {
                return (
                  <div>
                    <div className="flex md:hidden flex-row border-b border-slate-800 lg:flex-row px-2">
                      <div className="grow w-3/12">
                        <h1 className="text-base text-slate-300">
                          {match.match_time}
                        </h1>
                      </div>
                      <div className="grow w-3/4 flex flex-col ">
                        <div className="flex justify-between">
                          <img
                            className={`${
                              teams[0][`${match.team_a}`]
                                ? "lg:w-16 lg:h-16 h-10 w-10"
                                : "h-6 w-6 lg:w-20 lg:h-20 rounded-full m-1"
                            }`}
                            src={
                              teams[0][`${match.team_a}`]
                                ? `//images.weserv.nl/?url=${
                                    teams[0][`${match.team_a}`]
                                  }`
                                : `//images.weserv.nl/?url=${match.team_a_img}`
                            }
                            alt={match.team_b}
                          />
                          <h1 className="text-base text-blue-900 font-semibold">{`${match.team_a} v/s ${match.team_b}`}</h1>
                          <img
                            className={`${
                              teams[0][`${match.team_b}`]
                                ? "lg:w-16 lg:h-16 h-10 w-10"
                                : "h-6 w-6 lg:w-20 lg:h-20 rounded-full m-1"
                            }`}
                            src={
                              teams[0][`${match.team_b}`]
                                ? `//images.weserv.nl/?url=${
                                    teams[0][`${match.team_b}`]
                                  }`
                                : `//images.weserv.nl/?url=${match.team_b_img}`
                            }
                            alt={match.team_b}
                          />
                        </div>
                        <h1 className="text-sm text-slate-300">{`${match.matchs}, ${match.series}`}</h1>
                        <h1 className="text-sm text-slate-300">
                          {match.venue}
                        </h1>
                      </div>
                    </div>

                    <div className="md:flex hidden flex-row justify-between border-b border-slate-800 lg:flex-row px-2 py-2">
                      <div className="w-1/6">
                        <h1 className="text-xl text-slate-300">
                          {match.match_time}
                        </h1>
                      </div>
                      <div className="flex flex-row grow w-2/3">
                        <div className="grow w-1/2 mx-2">
                          <h1 className="text-xl font-semibold text-slate-300">
                            {match.series}
                          </h1>
                          <h1 className="text-sm text-slate-300">
                            {match.matchs}
                          </h1>
                        </div>
                        <div className="flex grow w-1/2">
                          <div className="w-1/6">
                            <img
                              className={`${
                                teams[0][`${match.team_a}`]
                                  ? "lg:w-16 lg:h-16 h-10 w-10"
                                  : "h-10 w-10 lg:w-12 lg:h-12 rounded-full"
                              }`}
                              src={
                                teams[0][`${match.team_a}`]
                                  ? `//images.weserv.nl/?url=${
                                      teams[0][`${match.team_a}`]
                                    }`
                                  : `//images.weserv.nl/?url=${match.team_a_img}`
                              }
                              alt={match.team_b}
                            />
                          </div>
                          <div className="flex flex-col self-start	w-4/6">
                            <h1 className="text-xl justify-self-start self-start	 font-semibold text-slate-300">{`${match.team_a} v/s ${match.team_b}`}</h1>
                            {/* <h1></h1> */}
                            <h1 className="text-sm text-slate-300">
                              {match.venue}
                            </h1>
                          </div>
                          <div className="w-1/6">
                            <img
                              className={`${
                                teams[0][`${match.team_b}`]
                                  ? "lg:w-16 lg:h-16 h-10 w-10"
                                  : "h-10 w-10 lg:w-12 lg:h-12 rounded-full"
                              }`}
                              src={
                                teams[0][`${match.team_b}`]
                                  ? `//images.weserv.nl/?url=${
                                      teams[0][`${match.team_b}`]
                                    }`
                                  : `//images.weserv.nl/?url=${match.team_b_img}`
                              }
                              alt={match.team_b}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              });
            }
          })}
        </div>
      ) : (
        <h1 className="text-slate-300">Loading...</h1>
      )}
    </div>
  );
};

export default UpcomingSinglePage;
