import wsContext from "../../../../src/Context/wsContext";
import React, { useState, useContext, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import teams from "../../../teams.json";

function SimpleSlider(props) {
  const ws = useContext(wsContext);
  const [liveData, setLiveData] = useState(undefined);
  const wsAction1 = "get_live_matches";
  const wsKey = "slider";
  useEffect(() => {
    ws.register(wsAction1, wsKey, function (data) {
      setLiveData(data);
    });
    ws.get(wsAction1, undefined, props.page);
    return () => {
      setLiveData(null);
      ws.unregister(wsAction1, wsKey);
    };
  }, []);
  const slidetoshow = Math.max(Math.min(liveData?.length, 3), 1);

  var settings = {
    dots: true,
    infinite: false,
    slidesToShow: slidetoshow,
    slidesToScroll: 3,
    cssEase: "linear",
    arrows: true,

    initialSlide: 0,
    pauseOnHover: true,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: slidetoshow >= 3 ? 1024 : 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          cssEase: "linear",
          dots: true,

          vertical: false,
          focusOnSelect: true,
          // adaptiveHeight: true,
          // verticalSwiping: true,
        },
      },
    ],
  };
  return (
    <React.Fragment>
      {liveData && liveData.length > 0 && liveData != undefined ? (
        <div className="justify-center flex">
          <div
            className={`lg:w-10/12 w-screen ${
              slidetoshow > 1 ? "pb-6" : "md:pb-4 pb-2"
            } self-center mt-2 md:mt-4`}
          >
            <Slider {...settings}>
              {liveData.map((data) => {
                if (data.betfair_id) {
                  return (
                    <></>
                    // <Link
                    //   style={{ textDecoration: "none" }}
                    //   to={`/matchinfo/live/${data.match_id}/${data.series_id}`}
                    // >
                    //   <div
                    //     key={data.match_id}
                    //     className="py-3 bg-slate-900 rounded-lg border-solid border-2 border-slate-800 mx-2"
                    //   >
                    //     <div className="justify-around items-center flex  mx-4 py-1">
                    //       <div className="flex justify-around items-center w-2/4 lg:mr-2 mr-1 bg-slate-800 rounded-lg">
                    //         <img
                    //           className={`${
                    //             teams[0][`${data.a}`]
                    //               ? "lg:w-16 lg:h-16 h-10 w-10"
                    //               : "h-6 w-6 lg:w-16 lg:h-16 rounded-full"
                    //           }`}
                    //           src={
                    //             teams[0][`${data.a}`]
                    //               ? `//images.weserv.nl/?url=${
                    //                   teams[0][`${data.a}`]
                    //                 }`
                    //               : `//images.weserv.nl/?url=${data.a}`
                    //           }
                    //           alt={data.a}
                    //         />
                    //         <div className="content-center ">
                    //           <h1
                    //             className={`self-center text-xsm ${
                    //               slidetoshow >= 3
                    //                 ? "xl:text-xsm"
                    //                 : "xl:text-base"
                    //             } text-center align-middle font-sans antialiased italic font-semibold text-red-50`}
                    //           >
                    //             {data.a}
                    //           </h1>
                    //           <h1
                    //             className={`self-center text-xsm ${
                    //               slidetoshow >= 3
                    //                 ? "xl:text-sm"
                    //                 : "xl:text-base"
                    //             }   text-center font-sans antialiased font-semibold ${
                    //               data.as[0]
                    //                 ? "text-red-50 italic"
                    //                 : "text-gray-400 nonitalic"
                    //             }`}
                    //           >
                    //             {`${
                    //               data.as[0]
                    //                 ? `${data.as[0]?.r} - ${data.as[0]?.w} (${data.as[0]?.o})`
                    //                 : ""
                    //             }`}
                    //             {/* / ${
                    //             data.hs[1]
                    //               ? `${data.hs[1].r} - ${data.hs[1].o} (${data.hs[1].w})`
                    //               : ""
                    //           }`} */}
                    //           </h1>
                    //         </div>
                    //         {/* <h1
                    //           className={`self-center sm:text-xsm md:text-sm ${
                    //             slidetoshow >= 3 ? "lg:text-sm" : "lg:text-xl"
                    //           }   text-center font-sans antialiased font-semibold ${
                    //             data.hs[1]
                    //               ? "text-red-50 italic"
                    //               : "text-gray-400 nonitalic"
                    //           }`}
                    //         >
                    //           {data.hs[1]
                    //             ? `${data.hs[1].r}/${data.hs[1].o} (${data.hs[1].w})`
                    //             : ""}
                    //         </h1> */}
                    //       </div>
                    //       <div className="flex justify-around items-center w-2/4 lg:ml-2 ml-1 bg-slate-800 rounded-lg">
                    //         <img
                    //           className={`${
                    //             teams[0][`${data.h}`]
                    //               ? "lg:w-16 lg:h-16 h-10 w-10"
                    //               : "h-6 w-6 lg:w-16 lg:h-16 rounded-full"
                    //           }`}
                    //           src={
                    //             teams[0][`${data.h}`]
                    //               ? `//images.weserv.nl/?url=${
                    //                   teams[0][`${data.h}`]
                    //                 }`
                    //               : `//images.weserv.nl/?url=${data.a}`
                    //           }
                    //           alt={data.h}
                    //         />
                    //         <div>
                    //           <h1
                    //             className={`self-center text-xsm ${
                    //               slidetoshow >= 3
                    //                 ? "xl:text-xsm"
                    //                 : "xl:text-base"
                    //             } text-center align-middle font-sans antialiased italic font-semibold text-red-50`}
                    //           >
                    //             {data.h}
                    //           </h1>
                    //           <h1
                    //             className={`self-center text-xsm ${
                    //               slidetoshow >= 3
                    //                 ? "xl:text-sm"
                    //                 : "xl:text-base"
                    //             }   text-center font-sans antialiased font-semibold ${
                    //               data.hs[0]
                    //                 ? "text-red-50 italic"
                    //                 : "text-gray-400 nonitalic"
                    //             }`}
                    //           >
                    //             {`${
                    //               data.hs[0]
                    //                 ? `${data.hs[0].r} - ${data.hs[0].w} (${data.hs[0].o})`
                    //                 : ""
                    //             } `}
                    //           </h1>

                    //           {/* <h1
                    //           className={`self-center sm:text-xsm md:text-sm ${
                    //             slidetoshow >= 3 ? "lg:text-sm" : "lg:text-xl"
                    //           }   text-center font-sans antialiased font-semibold ${
                    //             data.as[1]
                    //               ? "text-red-50 italic"
                    //               : "text-gray-400 nonitalic"
                    //           }`}
                    //         >
                    //           {data.as[1]
                    //             ? `${data.as[1].r}/${data.as[1].o} (${data.as[1].w})`
                    //             : ""}
                    //         </h1> */}
                    //         </div>
                    //       </div>
                    //     </div>
                    //     <div>
                    //       <h1
                    //         className={`text-center truncate text-xsm ${
                    //           slidetoshow >= 3 ? "xl:text-sm" : "xl:text-base"
                    //         } font-sans antialiased italic font-semibold text-slate-500`}
                    //       >
                    //         {data.series}
                    //       </h1>
                    //       <h1
                    //         className={`text-xsm ${
                    //           slidetoshow >= 3 ? "lg:text-sm" : "lg:text-base"
                    //         } text-center font-sans antialiased italic font-semibold ${
                    //           data.s ? "text-green-500" : "text-slate-500"
                    //         }`}
                    //       >
                    //         {data.s
                    //           ? data.s === "LU"
                    //             ? "Lunch Brake"
                    //             : data.s === "TU"
                    //             ? "Third Umpire"
                    //             : data.s === "WM"
                    //             ? "Over"
                    //             : data.s === "BR"
                    //             ? "Ball"
                    //             : data.s === "NO"
                    //             ? "Not Out"
                    //             : data.s === "ST"
                    //             ? "Stumps"
                    //             : data.s === "RE"
                    //             ? "Review Decision"
                    //             : data.s === "OT"
                    //             ? "Out"
                    //             : data.s === "NBC"
                    //             ? "No Ball Check"
                    //             : data.s === "6"
                    //             ? "Six"
                    //             : data.s === "TEA"
                    //             ? "Tea Break"
                    //             : data.s === "BU"
                    //             ? "Boundary Check"
                    //             : data.s === "DR"
                    //             ? "Drinks Break"
                    //             : data.s === "STO"
                    //             ? "Strategic Time-Out"
                    //             : data.s == "WD"
                    //             ? "Wide"
                    //             : data.s === "IB"
                    //             ? "Innings Break"
                    //             : data.s === "NA" && data.o.length > 0
                    //             ? `${
                    //                 data.o[data.o.length - 1].w !== "NA"
                    //                   ? "Wicket"
                    //                   : data.o[data.o.length - 1].e !== "NA"
                    //                   ? `${
                    //                       data.o[data.o.length - 1].r !== "NA"
                    //                         ? data.o[data.o.length - 1].r
                    //                         : ""
                    //                     }${data.o[data.o.length - 1].e}`
                    //                   : data.o[data.o.length - 1].r
                    //               }`
                    //             : data.s === "NA"
                    //             ? `EasyLiveLine`
                    //             : data.s
                    //           : "Waiting ..."}
                    //       </h1>
                    //       <h1
                    //         className={`text-xsm ${
                    //           slidetoshow >= 3 ? "xl:text-sm" : "xl:text-base"
                    //         } text-center font-sans antialiased italic font-semibold
                    //         text-yellow-color
                    //         `}
                    //       >
                    //         {data.m && !data.m.includes("....")
                    //           ? `${
                    //               data.m.includes("required") &&
                    //               data.as[0].ic === true
                    //                 ? data["h"]
                    //                 : data["a"]
                    //             } required ${data.m.replace(
                    //               "*" && "required",
                    //               ""
                    //             )}`
                    //           : `Fav Team : ${data.fav_team}`}
                    //         {/* {data.current_inning === 1
                    //           ? `Fav Team: ${data.fav_team}`
                    //           : data.ball_rem &&
                    //             `${
                    //               data.batting_team == data.team_a_id
                    //                 ? data.team_a_short
                    //                 : data.team_b_short
                    //             } Needs ${data.run_need} runs to win on ${
                    //               data.ball_rem
                    //             } balls`} */}
                    //       </h1>
                    //       {/* <div className="flex justify-center">
                    //         <h1
                    //           className={` sm:text-xsm md:text-sm ${
                    //             slidetoshow >= 3 ? "lg:text-sm" : "lg:text-xl"
                    //           } text-center font-sans antialiased italic font-bold	 text-orange-600`}
                    //         >
                    //           {data.match_status}
                    //         </h1>
                    //       </div> */}
                    //       <div className="bg-black">
                    //         {data.o != [] && data.o.length !== 0 ? (
                    //           <div>
                    //             <ul
                    //               className={`flex justify-center text-center font-sans antialiased italic font-semibold text-slate-500`}
                    //             >
                    //               <li
                    //                 className={`sm:text-xsm md:text-sm ${
                    //                   slidetoshow >= 3
                    //                     ? "lg:text-sm"
                    //                     : "lg:text-base"
                    //                 } self-center mr-1`}
                    //               >
                    //                 This Over:
                    //               </li>
                    //               {data.o.map((ball, index) => {
                    //                 return (
                    //                   <li
                    //                     className={`flex not-italic uppercase mx-1 justify-center items-center text-center font-sans my-1 h-4 w-4 ${
                    //                       ball.length > 2
                    //                         ? "text-xxsm"
                    //                         : "text-xsm "
                    //                     }  ${
                    //                       slidetoshow >= 3
                    //                         ? "md:w-4 md:h-4 lg:w-5 lg:h-5"
                    //                         : "md:w-5 md:h-5 lg:w-6 lg:h-6"
                    //                     } rounded-full text-white
                    //               ${
                    //                 ball.r == 6
                    //                   ? "bg-6-color"
                    //                   : ball.r == 4
                    //                   ? "bg-4-color"
                    //                   : ball.w !== "NA"
                    //                   ? "bg-w-color"
                    //                   : // : ball.r === "NA"
                    //                     // ? "bg-slate-400"
                    //                     "bg-slate-700"
                    //               }`}
                    //                   >{`${
                    //                     ball.w === "NA"
                    //                       ? `${ball.r !== "NA" ? ball.r : "?"}${
                    //                           ball.e !== "NA" ? ball.e : ""
                    //                         }`
                    //                       : ball.w
                    //                   }`}</li>
                    //                 );
                    //               })}
                    //             </ul>
                    //           </div>
                    //         ) : (
                    //           <div>
                    //             <ul
                    //               className={`flex justify-center text-center font-sans antialiased italic font-semibold text-slate-500`}
                    //             >
                    //               <li
                    //                 className={`m-1 sm:text-xsm md:text-sm ${
                    //                   slidetoshow >= 3
                    //                     ? "lg:text-sm"
                    //                     : "lg:text-base"
                    //                 } self-center mr-1`}
                    //               >
                    //                 {data.l === ""
                    //                   ? "Waiting for play ..."
                    //                   : "Last Over:"}
                    //               </li>
                    //               {/* <h1>{lastOver}</h1> */}
                    //               {data.l != "" &&
                    //                 data.l
                    //                   .substring(0, data.l.length - 1)
                    //                   .split(" ")
                    //                   .map((ball, index) => {
                    //                     return (
                    //                       <li
                    //                         className={`flex not-italic uppercase mx-1 justify-center items-center text-center font-sans my-1 h-4 w-4 ${
                    //                           ball.length > 2
                    //                             ? "text-xxsm"
                    //                             : "text-xsm "
                    //                         }  ${
                    //                           slidetoshow >= 3
                    //                             ? "md:w-4 md:h-4 lg:w-5 lg:h-5"
                    //                             : "md:w-5 md:h-5 lg:w-6 lg:h-6"
                    //                         } rounded-full text-white
                    //                     ${
                    //                       ball == 6
                    //                         ? "bg-6-color"
                    //                         : ball == 4
                    //                         ? "bg-4-color"
                    //                         : ball == "w"
                    //                         ? "bg-w-color"
                    //                         : "bg-slate-700"
                    //                     }`}
                    //                       >{`${ball}`}</li>
                    //                     );
                    //                   })}
                    //             </ul>
                    //           </div>
                    //         )}
                    //       </div>
                    //     </div>
                    //   </div>
                    // </Link>
                  );
                } else if (
                  data.match_status === "Live" ||
                  data.match_status === "Finished" ||
                  data.match_status === "Upcoming"
                ) {
                  return (
                    <Link
                      style={{ textDecoration: "none" }}
                      to={`/matchinfo/live/${data.match_id}/${data.series_id}`}
                    >
                      <div
                        key={data.match_id}
                        className="py-3 rounded-lg mx-2 bg-slate-900"
                        //                       className="py-3 @apply shadow-[0_8px_32px_0_rgba(_31,38,135,0.37_)] backdrop-blur-[_4px_] border rounded-[10px] border-solid border-[rgba(_255,255,255,0.18_)];
                        // background: rgba(255, 255, 255, 0.25);
                        // -webkit-backdrop-filter: blur(4px);"
                      >
                        <div className="justify-around items-center flex  mx-4 py-1 ">
                          <div className="flex justify-around items-center w-2/4 lg:mr-2 mr-1 bg-upcoming-matches-rgba rounded-lg">
                            <img
                              className={`${
                                teams[0][`${data.team_a}`]
                                  ? "lg:w-16 lg:h-16 h-10 w-10"
                                  : "h-6 w-6 lg:w-11 lg:h-11 lg:my-2.5 my-2 rounded-full"
                              }`}
                              src={
                                teams[0][`${data.team_a}`]
                                  ? `//images.weserv.nl/?url=${
                                      teams[0][`${data.team_a}`]
                                    }`
                                  : `//images.weserv.nl/?url=${data.team_a_img}`
                              }
                              alt={data.team_a}
                            />
                            <div className="content-center ">
                              <h1
                                className={`self-center text-xsm font-light ${
                                  slidetoshow >= 3
                                    ? "xl:text-sm"
                                    : "xl:text-base"
                                } text-center align-middle font-sans antialiased italic font-semibold text-red-50`}
                              >
                                {data.team_a_short}
                              </h1>
                              <h1
                                className={`self-center text-xsm ${
                                  slidetoshow >= 3
                                    ? "xl:text-sm"
                                    : "xl:text-base"
                                }   text-center font-sans antialiased font-semibold ${
                                  data.team_a_scores
                                    ? "text-red-50 italic"
                                    : "text-gray-400 nonitalic"
                                }`}
                              >
                                {data.team_a_scores_over &&
                                  (data.team_a_scores_over.length === 1 ? (
                                    <div className="flex lg:gap-2 gap-1">
                                      <h1
                                        className={`${
                                          slidetoshow >= 3
                                            ? "lg:text-base"
                                            : "lg:text-xl"
                                        } text-sm text-slate-300 font-normal truncate`}
                                      >
                                        {`${data.team_a_scores_over[0].score}${
                                          data.batting_team == data.team_a_id
                                            ? "*"
                                            : ""
                                        }`}
                                      </h1>
                                      <h1 className=" text-slate-300 font-normal">{`(${data.team_a_scores_over[0].over})`}</h1>
                                    </div>
                                  ) : data.team_a_scores_over.length === 2 ? (
                                    <div className="flex lg:gap-2 gap-1">
                                      <div className="flex gap-1">
                                        <h1
                                          className={`${
                                            slidetoshow >= 3
                                              ? "lg:text-base"
                                              : "lg:text-xl"
                                          } text-sm text-slate-300 font-normal truncate`}
                                        >
                                          {`${
                                            data.team_a_scores_over[1].score
                                          }${
                                            data.batting_team == data.team_a_id
                                              ? "*"
                                              : ""
                                          }`}
                                        </h1>
                                        <h1 className="text-slate-300 font-normal truncate">
                                          {`(${data.team_a_scores_over[1].over})`}
                                        </h1>
                                      </div>
                                    </div>
                                  ) : (
                                    <h1 className="text-slate-300 truncate">
                                      Yet to bat
                                    </h1>
                                  ))}
                              </h1>
                            </div>
                          </div>
                          <div className="flex justify-around items-center w-2/4 lg:ml-2 ml-1 bg-upcoming-matches-rgba rounded-lg">
                            <img
                              className={`${
                                teams[0][`${data.team_b}`]
                                  ? "lg:w-16 lg:h-16 h-10 w-10"
                                  : "h-6 w-6 lg:w-11 lg:h-11 lg:my-2.5 my-2 rounded-full"
                              }`}
                              src={
                                teams[0][`${data.team_b}`]
                                  ? `//images.weserv.nl/?url=${
                                      teams[0][`${data.team_b}`]
                                    }`
                                  : `//images.weserv.nl/?url=${data.team_b_img}`
                              }
                              alt={data.team_b}
                            />
                            <div>
                              <h1
                                className={`self-center text-center font-sans text-xsm font-light ${
                                  slidetoshow >= 3
                                    ? "xl:text-sm"
                                    : "xl:text-base"
                                } antialiased italic font-semibold text-red-50`}
                              >
                                {data.team_b_short}
                              </h1>
                              <h1
                                className={`text-xsm ${
                                  slidetoshow >= 3
                                    ? "xl:text-sm"
                                    : "xl:text-base"
                                } self-center text-center font-sans antialiased  font-semibold ${
                                  data.team_b_scores
                                    ? "text-red-50 italic"
                                    : "text-gray-400 nonitalic"
                                }`}
                              >
                                {data.team_b_scores_over &&
                                  (data.team_b_scores_over.length === 1 ? (
                                    <div className="flex lg:gap-2 gap-1">
                                      <h1
                                        className={`${
                                          slidetoshow >= 3
                                            ? "xl:text-base"
                                            : "xl:text-xl"
                                        } text-sm text-slate-300 font-normal truncate`}
                                      >
                                        {`${data.team_b_scores_over[0].score}${
                                          data.batting_team == data.team_b_id
                                            ? "*"
                                            : ""
                                        }`}
                                      </h1>
                                      <h1 className="truncate text-slate-300 font-normal">{`(${data.team_b_scores_over[0].over})`}</h1>
                                    </div>
                                  ) : data.team_b_scores_over.length === 2 ? (
                                    <div className="flex lg:gap-2 gap-1">
                                      <div className="flex gap-1">
                                        <h1
                                          className={`${
                                            slidetoshow >= 3
                                              ? "xl:text-base"
                                              : "xl:text-xl"
                                          } text-sm text-slate-300 font-normal truncate`}
                                        >
                                          {`${
                                            data.team_b_scores_over[1].score
                                          }${
                                            data.batting_team == data.team_b_id
                                              ? "*"
                                              : ""
                                          }`}
                                        </h1>
                                        <h1 className="text-slate-300 font-normal truncate">
                                          {`(${data.team_b_scores_over[1].over})`}
                                        </h1>
                                      </div>
                                    </div>
                                  ) : (
                                    <h1 className="truncate text-slate-300">
                                      Yet to bat
                                    </h1>
                                  ))}
                              </h1>
                            </div>
                          </div>
                        </div>
                        <div>
                          <h1
                            className={`text-center truncate text-xsm ${
                              slidetoshow >= 3 ? "xl:text-sm" : "xl:text-base"
                            } font-sans antialiased italic font-semibold text-slate-500`}
                          >
                            {`${data.series} (${data.matchs})`}
                          </h1>
                          <h1
                            className={`text-xsm ${
                              slidetoshow >= 3 ? "lg:text-sm" : "lg:text-base"
                            } text-center font-sans antialiased italic font-semibold ${
                              data.first_circle
                                ? "text-green-500"
                                : "text-slate-500"
                            }`}
                          >
                            {data.first_circle
                              ? data.first_circle
                              : "Team plays soon ..."}
                          </h1>
                          <h1
                            className={`text-xsm ${
                              slidetoshow >= 3 ? "xl:text-sm" : "xl:text-base"
                            } text-center font-sans antialiased italic font-semibold ${
                              data.ball_rem
                                ? "text-yellow-color"
                                : "text-green-500"
                            }`}
                          >
                            {data.match_status != "Finished" ? (
                              data.match_type !== "Test" ? (
                                data.current_inning === 1 ? (
                                  `Fav Team: ${data.fav_team}`
                                ) : data.ball_rem ? (
                                  `${
                                    data.batting_team == data.team_a_id
                                      ? data.team_a_short
                                      : data.team_b_short
                                  } Needs ${data.run_need} runs to win on ${
                                    data.ball_rem
                                  } balls`
                                ) : (
                                  "EasyLiveLine"
                                )
                              ) : data.match_type === "Test" &&
                                (data.current_inning == 2 ||
                                  data.current_inning == 3) ? (
                                data.trail_lead
                              ) : data.current_inning == 4 && data.ball_rem ? (
                                `${
                                  data.batting_team == data.team_a_id
                                    ? data.team_a_short
                                    : data.team_b_short
                                } Needs ${data.run_need} runs to win on ${
                                  data.ball_rem
                                } balls`
                              ) : (
                                `Fav Team: ${data.fav_team}`
                              )
                            ) : (
                              <h1 className="text-stone-400">
                                {data.match_status}
                              </h1>
                            )}
                          </h1>
                          <div className="bg-black">
                            {data.last4overs != [] &&
                            data.last4overs !== undefined &&
                            data.last4overs.length !== 0 ? (
                              <div>
                                <ul
                                  className={`flex justify-center text-center font-sans antialiased italic font-semibold text-slate-500`}
                                >
                                  <li
                                    className={`text-xsm ${
                                      slidetoshow >= 3
                                        ? "xl:text-sm"
                                        : "xl:text-base"
                                    } self-center mr-1`}
                                  >
                                    This Over:
                                  </li>
                                  {data.last4overs[
                                    data.last4overs.length - 1
                                  ]?.balls.map((ball) => {
                                    return (
                                      <li
                                        className={`flex not-italic uppercase mx-1 justify-center items-center text-center font-sans my-1 h-4 w-4 ${
                                          ball.length > 2
                                            ? "text-xxsm"
                                            : "text-xsm "
                                        }  ${
                                          slidetoshow >= 3
                                            ? "md:w-4 md:h-4 lg:w-5 lg:h-5"
                                            : "md:w-5 md:h-5 lg:w-6 lg:h-6"
                                        } rounded-full text-white
                                      ${
                                        ball == 6
                                          ? "bg-6-color"
                                          : ball == 4
                                          ? "bg-4-color"
                                          : ball == "w"
                                          ? "bg-w-color"
                                          : "bg-slate-700"
                                      }`}
                                      >{`${ball}`}</li>
                                    );
                                  })}
                                </ul>
                              </div>
                            ) : data.last36ball &&
                              data.last36ball.length > 0 ? (
                              <ul
                                className={`flex justify-center text-center font-sans antialiased italic font-semibold text-slate-500`}
                              >
                                <li
                                  className={`${
                                    slidetoshow >= 3 && "xl:text-sm"
                                  } self-center mr-1`}
                                >
                                  This Over:
                                </li>
                                {data.last36ball?.map((ball) => {
                                  return (
                                    <li
                                      className={`flex justify-center items-center text-center font-sans my-1  not-italic uppercase mx-1 ${
                                        slidetoshow >= 3
                                          ? "w-5 h-5 text-xsm"
                                          : "w-6 h-6 text-sm"
                                      } rounded-full text-white
                                    ${
                                      ball == 6
                                        ? "bg-6-color"
                                        : ball == 4
                                        ? "bg-4-color"
                                        : ball == "w"
                                        ? "bg-w-color"
                                        : "bg-slate-700"
                                    }`}
                                    >{`${ball}`}</li>
                                  );
                                })}
                              </ul>
                            ) : (
                              <div className="py-1">
                                <h1
                                  className={`text-xsm md:text-sm ${
                                    slidetoshow >= 3
                                      ? "xl:text-sm"
                                      : "xl:text-base"
                                  } justify-center text-center font-sans antialiased italic font-semibold text-slate-500`}
                                >
                                  Please Wait...
                                </h1>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </Link>
                  );
                }
              })}
            </Slider>
          </div>
        </div>
      ) : liveData && liveData?.toString() == [] ? (
        <div className="flex justify-center items-center text-2xl text-amber-50	h-20">
          <div>
            <h1>No Live Match Yet</h1>
          </div>
        </div>
      ) : (
        liveData === undefined && (
          <div className="flex justify-center items-center text-2xl text-amber-50	h-20">
            <Skeleton count={5} />
          </div>
        )
      )}
    </React.Fragment>
  );
}

export default SimpleSlider;
