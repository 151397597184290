import React, { useContext, useEffect, useState } from "react";
import wsContext from "../../../../src/Context/wsContext";
import { useSelector } from "react-redux";

const Multistreming = () => {
  const ws = useContext(wsContext);
  const userData = useSelector((state) => state.user);
  const [streamURL, setStreamURL] = useState(undefined);
  const wsAction1 = "get_multistream_link";
  const wsKey = "single_live_page";
  const currentDate = new Date(new Date().toISOString().split("T")[0]);
  const userDate =
    userData && userData.user !== null
      ? new Date(userData.user.endDate)
      : undefined;

  const css = `main{
    display:flex;
    flex-direction:column;
    align-items:center;
  }
  iframe{
    flex-grow:2;
  }`;

  useEffect(() => {
    if (userData && userData.user) {
      ws.register(wsAction1, wsKey, async function (data) {
        // console.log(50, data);
        setStreamURL(data);
      });
      ws.get(
        wsAction1,
        undefined,
        "multistream",
        undefined,
        undefined,
        undefined,
        userData.user.username,
        undefined,
        userData.user.type
      );

      return () => {
        setStreamURL(null);
        ws.unregister(wsAction1, wsKey);
      };
    }
  }, [userData]);
  return (
    <>
      {streamURL?.playLink &&
        ws.isAuthenticated &&
        userData &&
        (userData.user.type === "Host" ||
          (userData.user.type === "Subscriber" &&
            userData.user.endDate &&
            +userDate >= +currentDate) ||
          userData.user.type === "Owner") && (
          <>
            <style>{css}</style>
            <iframe
              class="md:h-2/3 md:w-4/6 w-full h-full aspect-video"
              title="Live Match"
              frameborder="0"
              allowFullScreen="true"
              webkitallowfullscreen="true"
              mozallowfullscreen="true"
              src={streamURL?.playLink}
            ></iframe>
          </>
        )}
    </>
  );
};

export default Multistreming;
