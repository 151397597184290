import React, { useState, useContext, useEffect } from "react";
import wsContext from "../../../Context/wsContext";
import { useParams } from "react-router-dom";
import Menu from "../../UI/header";

function DetailedNews(props) {
  const ws = useContext(wsContext);
  const params = useParams();
  const newsID = params.newsId;
  const [detailedNews, setDetailedNews] = useState([]);
  const wsAction = "get_detailed_news";
  const wsKey = "single_news";

  useEffect(() => {
    ws.register(wsAction, wsKey, function (data) {
      setDetailedNews(data);
    });
    ws.get(wsAction, newsID, "single_news");
    return () => {
      ws.unregister(wsAction, wsKey);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      {/* <Menu /> */}
      {detailedNews.toString() != [] && (
        <div>
          <h1 className="text-slate-300">{detailedNews.title}</h1>
          <img
            loading="lazy"
            src={detailedNews.image}
            alt={detailedNews.title}
          ></img>
          {/* {detailedNews.content} */}
          {/* {detailedNews.content.map((paragraph) => {
            return React.createElement(
              "div",
              { key: Math.random(), className: "text-slate-300" },
              paragraph
            );
          })} */}
          <div
            className="text-slate-300"
            dangerouslySetInnerHTML={{ __html: detailedNews.content }}
          ></div>
        </div>
      )}
      {detailedNews.length === 0 && <h1>Loading Data</h1>}
    </React.Fragment>
  );
}

export default DetailedNews;
