import { Link } from "react-router-dom";
import wsContext from "../../../Context/wsContext";
import React, { useState, useContext, useEffect } from "react";
import Slider from "react-slick";
import teams from "../../../teams.json";

function UpcomingMatches(props) {
  const ws = useContext(wsContext);
  const [upcomingMatches, setUpcomingMatches] = useState([]);
  // const [page, setPage] = useState(10);
  const wsAction = "get_fixtures";
  const wsKey = "upcomingMatches";

  useEffect(() => {
    ws.register(wsAction, wsKey, function (data) {
      // console.log("get_fixtures", data);
      setUpcomingMatches(data);
    });

    ws.get(wsAction, undefined, props.page);

    return () => {
      setUpcomingMatches(null);
      ws.unregister(wsAction, wsKey);
    };
  }, []);

  var settings = {
    // dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          // dots: true,
        },
      },
      // {
      //   breakpoint: 600,
      //   settings: {
      //     slidesToShow: 2,
      //     slidesToScroll: 2,
      //     initialSlide: 2,
      //   },
      // },
      // {
      //   breakpoint: 480,
      //   settings: {
      //     slidesToShow: 1,
      //     slidesToScroll: 1,
      //   },
      // },
    ],
  };

  // function getTimeDiff(time) {
  //   let currentTime = new Date().getTime();
  //   let matchTime = new Date(time);
  //   console.log(currentTime);
  //   console.log(matchTime);
  // }
  // getTimeDiff();

  return (
    <React.Fragment>
      {upcomingMatches.toString() != "[]" ? (
        <div className="flex justify-center ">
          <div className="text-slate-200 w-10/12 self-center">
            {/* <div className="my-2 text-slate-300  font-mono font-bold">
              Upcoming
            </div> */}
            {/* <h2> Upcomming </h2> */}
            <Slider {...settings}>
              {upcomingMatches.map((match) => {
                return (
                  <React.Fragment>
                    <div
                      id="toast-notification"
                      className="mx-2 mb-3 max-w-4xl p-2 text-slate-900 bg-white rounded-lg shadow dark:bg-gray-900 dark:text-gray-300"
                      role="alert"
                    >
                      <div className="flex place-content-center items-center w-full">
                        <div className="relative flex justify-end w-1/6 ">
                          <img
                            className={`${
                              teams[0][`${match.team_a}`]
                                ? "w-16 h-16"
                                : "w-12 h-12 rounded-full"
                            }`}
                            src={
                              teams[0][`${match.team_a}`]
                                ? `//images.weserv.nl/?url=${
                                    teams[0][`${match.team_a}`]
                                  }`
                                : `//images.weserv.nl/?url=${match.team_a_img}`
                            }
                            alt={match.team_a}
                          />
                        </div>
                        <div className="mx-3 text-sm font-normal w-2/3 grow">
                          <div className=" truncate lg:text-base text-xsm md:text-sm font-semibold text-gray-900 dark:text-white">
                            {`${match.team_a} vs ${match.team_b}`}
                          </div>
                          <div className="truncate lg:text-base text-xsm md:text-sm font-semibold text-gray-400">
                            {`${match.series} - ${match.matchs}`}
                          </div>
                          <span className="lg:text-base text-xsm md:text-sm font-medium text-blue-600 dark:text-blue-500">
                            {`${match.match_date} - ${match.match_time}`}
                          </span>
                        </div>
                        <div className="relative flex justify-start w-1/6">
                          <img
                            className={`${
                              teams[0][`${match.team_b}`]
                                ? "w-16 h-16"
                                : "w-12 h-12 rounded-full"
                            }`}
                            src={
                              teams[0][`${match.team_b}`]
                                ? `//images.weserv.nl/?url=${
                                    teams[0][`${match.team_b}`]
                                  }`
                                : `//images.weserv.nl/?url=${match.team_b_img}`
                            }
                            alt={match.team_b}
                          />
                        </div>
                      </div>
                    </div>
                    {/* </Link> */}
                  </React.Fragment>
                );
              })}
            </Slider>
          </div>
        </div>
      ) : (
        <h1 className="text-stone-300">Loading...</h1>
      )}
    </React.Fragment>
  );
}

export default UpcomingMatches;
