import Marquee from "react-fast-marquee";
import React, { useState, useEffect, useContext } from "react";
import wsContext from "../../Context/wsContext";
import { useSelector } from "react-redux";

function Banner() {
  const ws = useContext(wsContext);
  const userData = useSelector((state) => state.user);
  const [banner, setBanner] = useState(false);
  const wsAction = "get_banner";
  const wsKey = "header";
  useEffect(() => {
    if (userData && userData.user.master > 0 && userData.user.master !== null) {
      ws.register(wsAction, wsKey, function (data) {
        if (data.banner !== null) {
          setBanner(data.banner);
        } else {
          setBanner("Made by Mohit🏏");
        }
      });
      ws.get(
        wsAction,
        undefined,
        "homepage",
        undefined,
        undefined,
        undefined,
        userData.user.master
      );
      return () => {
        setBanner(false);
        ws.unregister(wsAction, wsKey);
      };
    } else {
      setBanner("This Website is made by Mohit for learning purpose🏏");
    }
  }, [userData]);
  return (
    <Marquee
      pauseOnHover={true}
      gradient={false}
      gradientColor={[12, 19, 26]}
      className="bg-red-700"
      // height={600}
    >
      <h1 className="text-white font-semibold lg:text-xl text-base">
        {banner ? banner : "Loading..."}
      </h1>
    </Marquee>
  );
}

export default Banner;
