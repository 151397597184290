import wsContext from "../../../Context/wsContext";
import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";

const Cricket_news = (props) => {
  const ws = useContext(wsContext);
  const [newsData, setNewsData] = useState([]);
  const [page, setPage] = useState(6);
  const wsAction = "get_news";
  const wsKey = "news";

  useEffect(() => {
    ws.register(wsAction, wsKey, function (data) {
      setNewsData(data);
    });
    ws.get(wsAction, undefined, "homepage");
    return () => {
      ws.unregister(wsAction, wsKey);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  let slicedData = newsData.slice(0, page);

  return slicedData.toString() != [] ? (
    <div className="flex space-y-4 flex-col lg:mx-2 items-center lg:my-3 w-4/6">
      {slicedData.map((news) => {
        return (
          <div className="min-w-full ">
            <Link
              to={`/news/${news.news_id}`}
              className="flex flex-col items-center bg-white rounded-lg shadow md:flex-row w-full dark:bg-slate-900 dark:hover:bg-gray-800"
            >
              <div className="w-1/2">
                <img
                  loading="lazy"
                  className=" object-cover w-full rounded-t-lg h-full  md:rounded-none md:rounded-l-lg"
                  src={news.image}
                  alt={news.title}
                />
              </div>
              <div className="flex flex-col justify-between p-4 leading-normal w-1/2">
                <h5 className="mb-2 lg:text-base text-xsm md:text-sm font-bold tracking-tight text-gray-900 dark:text-white">
                  {news.title}
                </h5>
                <p className="mb-3 lg:text-base text-xsm md:text-sm italic font-normal text-gray-700 dark:text-gray-400">
                  {news.description}
                </p>
                <Link
                  to={`/news/${news.news_id}`}
                  class="lg:text-base text-sm font-semibold leading-6 text-slate-300"
                >
                  Read More <span aria-hidden="true">&rarr;</span>
                </Link>
              </div>
            </Link>
          </div>
        );
      })}
    </div>
  ) : (
    // <h1 className="text-slate-300">Loading News...</h1>
    <div className="lg:mx-10 w-4/6">
      <div className="flex flex-col animate-pulse">
        <div className="ml-4 mt-2 w-full">
          <h3 className="h-4 bg-gray-200 rounded-md dark:bg-gray-700 w-2/5"></h3>
          <ul className="mt-5 space-y-3">
            <li className="w-full h-4 bg-gray-200 rounded-md dark:bg-gray-700"></li>
            <li className="w-full h-4 bg-gray-200 rounded-md dark:bg-gray-700"></li>
            <li className="w-full h-4 bg-gray-200 rounded-md dark:bg-gray-700"></li>
            <li className="w-full h-4 bg-gray-200 rounded-md dark:bg-gray-700"></li>
          </ul>
        </div>
        <div className="ml-4 mt-2 w-full">
          <h3 className="h-4 bg-gray-200 rounded-md dark:bg-gray-700 w-2/5"></h3>
          <ul className="mt-5 space-y-3">
            <li className="w-full h-4 bg-gray-200 rounded-md dark:bg-gray-700"></li>
            <li className="w-full h-4 bg-gray-200 rounded-md dark:bg-gray-700"></li>
            <li className="w-full h-4 bg-gray-200 rounded-md dark:bg-gray-700"></li>
            <li className="w-full h-4 bg-gray-200 rounded-md dark:bg-gray-700"></li>
          </ul>
        </div>
        <div className="ml-4 mt-2 w-full">
          <h3 className="h-4 bg-gray-200 rounded-md dark:bg-gray-700 w-2/5"></h3>
          <ul className="mt-5 space-y-3">
            <li className="w-full h-4 bg-gray-200 rounded-md dark:bg-gray-700"></li>
            <li className="w-full h-4 bg-gray-200 rounded-md dark:bg-gray-700"></li>
            <li className="w-full h-4 bg-gray-200 rounded-md dark:bg-gray-700"></li>
            <li className="w-full h-4 bg-gray-200 rounded-md dark:bg-gray-700"></li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Cricket_news;
