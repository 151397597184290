import { Link } from "react-router-dom";
import wsContext from "../../../Context/wsContext";
import React, { useState, useContext, useEffect } from "react";
import teams from "../../../teams.json";
import locationPng from "../../../pin.png";
// import Slider from "react-slick";

function UpcomingMatches(props) {
  const ws = useContext(wsContext);
  const [upcomingMatches, setUpcomingMatches] = useState([]);
  // const [page, setPage] = useState(10);
  const wsAction = "get_fixtures";
  const wsKey = props.key;

  useEffect(() => {
    ws.register(wsAction, wsKey, function (data) {
      setUpcomingMatches(data);
    });

    ws.get(wsAction, undefined, "homepage");

    return () => {
      ws.unregister(wsAction, wsKey);
    };
  }, []);

  return (
    <React.Fragment>
      {upcomingMatches && upcomingMatches.toString() ? (
        <div className="flex">
          <div className="flow-root grow">
            <ul className="">
              {upcomingMatches.map((person) => (
                <li className="mb-1">
                  <div className=" flex flex-col justify-around items-start w-screen">
                    <div className="flex justify-start items-start bg-slate-700 w-full py-1">
                      <p className="text-sm font-medium text-slate-300 mx-1 truncate pl-2">
                        {`${person.series} (${person.matchs})`}
                      </p>
                    </div>
                    <div className="flex-shrink-0 flex w-full divide-x divide-slate-800">
                      <div className="flex flex-col w-3/4">
                        <div className="flex justify-start items-center mx-2 my-1">
                          <img
                            className="lg:h-16 lg:w-16 h-8 w-8 rounded-full mx-1"
                            src={teams[0][person.team_a]}
                            alt=""
                          />
                          <span className="text-base text-slate-400 text-center font-normal">
                            {person.team_a}
                          </span>
                        </div>
                        <div className="flex justify-start items-center mx-2 my-1">
                          <img
                            className="lg:h-16 lg:w-16 h-8 w-8 rounded-full mx-1"
                            src={teams[0][person.team_b]}
                            alt=""
                          />
                          <span className=" text-base text-slate-400 text-center font-normal">
                            {person.team_b}
                          </span>
                        </div>
                        <div className="flex justify-start items-center ml-2.5 my-1">
                          <img
                            className="mx-1 h-6 w-6"
                            src={locationPng}
                            // src="https://crex.live/assets/icon/location.svg"
                            alt="team_image"
                          />
                          <p
                            className={`text-base text-gray-500 ml-1 truncate`}
                          >
                            {/* {person.venue.split(",")[1]} */}
                            {person.venue}
                          </p>
                        </div>
                      </div>
                      <div className="w-1/4 flex flex-col justify-center items-center">
                        <p className="text-base font-medium text-slate-400 ">
                          {person.match_date}
                        </p>
                        <p className="text-base font-medium text-blue-500">
                          {person.match_time}
                        </p>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
            <div className="">
              <Link
                to="/cricket/fixtures"
                className="flex w-full items-center justify-center rounded-md  bg-slate-700 px-4 py-2 text-sm font-medium text-slate-300 shadow-sm hover:bg-gray-50"
              >
                View all
              </Link>
            </div>
          </div>
        </div>
      ) : (
        <h1>Loading</h1>
      )}
    </React.Fragment>
  );
}

export default UpcomingMatches;
